<template>
  <div>
    <div>
      <div>
        <el-input
            style="width: 200px;margin-right: 20px"
            size="mini"
            placeholder="朋友名称"
            v-model="searchFriend.name"
            clearable
            @keydown.enter.native="searchFriend.currentPage = 1; searchFriend.size = 10; initFriend()"
        ></el-input>
        <el-select
            v-model="searchFriend.sex"
            size="mini"
            style="width: 200px;margin-right: 20px"
            placeholder="朋友性别"
            clearable
            filterable
            @change="searchFriend.currentPage = 1; searchFriend.size = 10; initFriend()"
        >
          <el-option
              v-for="item in sexs"
              :key="item"
              :label="item"
              :value="item"
          >
          </el-option>
        </el-select>
        <el-button icon="el-icon-delete" type="warning" size="mini" @click="searchFriend.name = ''; searchFriend.sex = '';">
          重置
        </el-button>
        <el-button
            type="success"
            size="mini"
            icon="el-icon-search"
            @click="searchFriend.currentPage = 1; searchFriend.size = 10; initFriend()"
        >
          搜索
        </el-button>
        <el-button
            type="primary"
            size="mini"
            icon="el-icon-plus"
            @click="showAddFriendView"
            v-if="roleButton.buttonLevelComponents !== null && roleButton.buttonLevelComponents.indexOf('Friend') !== -1"
        >
          添加朋友
        </el-button>
      </div>
    </div>

    <div style="margin-top: 10px">
      <el-table
          size="mini"
          :data="friendData"
          border
          stripe
          :row-class-name="tableRowClassName"
          v-loading="loading"
          element-loading-text="拼命加载中"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.8)"
      >
        <el-table-column
            label="朋友名称"
            align="center"
            prop="name"
        ></el-table-column>
        <el-table-column
            label="朋友年龄"
            align="center"
            prop="age"
            sortable
        ></el-table-column>
        <el-table-column
            label="朋友性别"
            align="center"
            prop="sex"
        ></el-table-column>
        <el-table-column
            label="朋友地址"
            align="center"
            prop="address"
        ></el-table-column>
        <el-table-column
            label="朋友头像"
            width="100"
        >
          <template #default="scope">
            <el-image
                style="width: 50px; height: 50px"
                :src="initAvatar(scope.row.avatar)"
                :preview-src-list="[initAvatar(scope.row.avatar)]"
            >
              <template #error>
                <div
                    class="image-slot"
                    style="text-align: center; line-height: 49px; border: 1px solid #ccc;"
                >
                  <i class="el-icon-picture-outline"></i>
                </div>
              </template>
            </el-image>
          </template>
        </el-table-column>
        <el-table-column
            label="创建时间"
            width="200"
            align="center"
            prop="createTime"
            sortable
        ></el-table-column>
        <el-table-column
            label="更新时间"
            width="200"
            align="center"
            prop="updateTime"
            sortable
        ></el-table-column>
        <el-table-column
            label="操作"
            fixed="right"
            width="100"
        >
          <template slot-scope="scope">
            <el-button v-if="roleButton.buttonLevelComponents !== null && roleButton.buttonLevelComponents.indexOf('Friend') !== -1" size="mini" style="padding: 3px" type="primary" @click="showUpdateFriendView(scope.row)">编辑</el-button>
            <el-button v-if="roleButton.buttonLevelComponents !== null && roleButton.buttonLevelComponents.indexOf('Friend') !== -1" size="mini" style="padding: 3px" type="danger" @click="deleteFriend(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div style="display: flex; justify-content: flex-end; margin-top: 5px">
      <el-pagination
          background
          layout="sizes, prev, pager, next, jumper, ->, total, slot"
          :current-page="searchFriend.currentPage"
          :page-size="searchFriend.size"
          :total="total"
          @current-change="currentChange"
          @size-change="sizeChange"
      >
      </el-pagination>
    </div>

    <el-dialog
        :close-on-click-modal="false"
        :title="title"
        @close="closeDialog()"
        :visible.sync="dialogVisible"
        width="400px">
      <el-form ref="friendForm" :model="friend" :rules="rules">
        <table>
          <tr>
            <el-form-item label="朋友名称" prop="name">
              <el-input
                  size="mini"
                  style="width: 200px"
                  v-model="friend.name"
                  placeholder="请输入朋友名称"
              ></el-input>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="朋友年龄">
              <el-input
                  size="mini"
                  style="width: 200px"
                  v-model="friend.age"
                  placeholder="请输入朋友年龄"
              ></el-input>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="朋友性别" prop="sex">
              <el-select
                  v-model="friend.sex"
                  size="mini"
                  style="width: 200px"
                  placeholder="朋友性别"
                  clearable
                  filterable
              >
                <el-option
                    v-for="item in sexs"
                    :key="item"
                    :label="item"
                    :value="item"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="朋友地址" prop="address">
              <el-input
                  size="mini"
                  style="width: 200px"
                  v-model="friend.address"
                  placeholder="请输入朋友地址"
              ></el-input>
            </el-form-item>
          </tr>
          <tr>
              <el-form-item label="朋友头像">
                <el-upload
                    ref="upload"
                    :show-file-list="false"
                    :action="filesUploadUrl"
                    :on-success="filesUploadSuccess"
                >
                  <el-button type="primary">点击上传</el-button>
                </el-upload>
              </el-form-item>
              <div style="margin-left: 130px">
                <el-image
                    v-if="friend.avatar !== undefined && friend.avatar !== null && friend.avatar !== ''"
                    style="width: 100px; height: 100px; margin-top: 20px"
                    :src="initAvatar(friend.avatar)"
                    :preview-src-list="[initAvatar(friend.avatar)]"
                ></el-image>
              </div>
            </tr>
        </table>
      </el-form>
      <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="doFriend">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "Friend",
  data() {
    return {
      loading: false,
      searchFriend: {
        name: '',
        sex: '',
        currentPage: 1,
        size: 10,
      },
      friend: {
        id: null,
        name: '',
        age: '',
        sex: '',
        address: '',
        avatar: '',
      },
      total: 0,
      friendData: [],
      dialogVisible: false,
      title: '',
      rules: {
        name: [{required: true, message: "请输入朋友名称", trigger: "blur"}],
        sex: [{required: true, message: "请选择朋友性别", trigger: "change"}],
      },
      filesUploadUrl: "http://" + window.server.filesUploadUrl + "/files/upload",
    };
  },
  computed: {
    sexs() {
      return this.$store.state.sexs;
    },
    roleButton() {
      return this.$store.state.currentRole;
    }
  },
  mounted() {
    this.initFriend();
  },
  methods: {
    tableRowClassName({row, rowIndex}) {
      if (rowIndex % 2 !== 0) {
        return 'warning-row';
      } else if (rowIndex % 2 === 0) {
        return 'success-row';
      }
      return '';
    },
    initFriend() {
      this.loading = true;
      this.postRequest("/record/friend/getPageData", this.searchFriend).then(resp => {
        this.loading = false;
        if (resp) {
          this.friendData = resp.data;
          this.total = resp.total;
        }
      })
    },
    currentChange(page) {
      this.searchFriend.currentPage = page;
      this.initFriend();
    },
    sizeChange(size) {
      this.searchFriend.size = size;
      this.initFriend();
    },
    deleteFriend(data) {
      this.$confirm(
          "此操作将永久删除[" + data.name + "]该朋友, 是否继续?",
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
      ).then(() => {
        this.deleteRequest("/record/friend/" + data.id).then(resp => {
              if (resp) {
                this.initFriend();
              }
            }
        );
      }).catch(() => {
        this.$message({
          type: "info",
          message: "已取消删除",
        });
      });
    },
    showAddFriendView() {
      this.friend = {
        id: null,
        name: '',
        age: '',
        sex: '',
        address: '',
        avatar: ''
      };
      this.dialogVisible = true;
      this.title = "添加朋友";
    },
    showUpdateFriendView(data) {
      // 拷贝
      this.friend = JSON.parse(JSON.stringify(data));
      this.dialogVisible = true;
      this.title = "编辑朋友";
    },
    doFriend() {
      if (this.friend.id) {
        // 编辑
        this.$refs["friendForm"].validate((valid) => {
          if (valid) {
            this.putRequest("/record/friend", this.friend).then(resp => {
              if (resp) {
                this.dialogVisible = false;
                this.initFriend();
              }
            })
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      } else {
        // 添加
        this.$refs["friendForm"].validate((valid) => {
          if (valid) {
            this.postRequest("/record/friend", this.friend).then(resp => {
              if (resp) {
                this.dialogVisible = false;
                this.initFriend();
              }
            })
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      }
    },
    filesUploadSuccess(res) {
      this.friend.avatar = res.data;
    },
    closeDialog() {
      if (this.$refs["upload"]) {
        this.$refs["upload"].clearFiles(); // 清除历史文件列表
      }
      this.$refs["friendForm"].resetFields();
    },
    initAvatar(image) {
      return window.server.apiBase + "/files?filePath=" + image + "&name=";
    }
  },
};
</script>

<style>
.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}

label {
  display: inline-block;
  width: 100px;
  text-align: right;
}
</style>
