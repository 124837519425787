import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

const now = new Date();

const store = new Vuex.Store({
    state: {
        currentRole: JSON.parse(window.sessionStorage.getItem("role")),
        sources: ['pc', 'app'],
        stars: [3, 4, 5],
        entries: ['攻击力', '防御力', '生命值', '元素充能效率', '元素精通', '暴击率', '暴击伤害', '物理伤害加成'],
        lifeSeats: [0, 1, 2, 3, 4, 5, 6],
        steps: [1, 2, 3, 4, 5],
        attributes: ['风', '火', '雷', '水', '冰', '岩', '草'],
        positions: ['主C', '副C', '奶妈', '增伤', '护盾', '护盾/奶妈', '护盾/增伤', '副C/充能', '副C/奶妈', '聚怪/增伤'],
        armsTypes: ['弓箭', '单手剑', '双手剑', '枪', '法器'],
        gameTypes: ['手机游戏', 'PC网络游戏', 'PC单机游戏'],
        sexs: ['男', '女'],
        ysAddresses: ['蒙德', '璃月', '稻妻', '至冬', '须弥'],
        mrfzLevelTypes: ['未精', '精一', '精二'],
        mrfzMasteryTypes: ['0', '1', '2', '3', '4', '5', '6', '7', '专一', '专二', '专三'],
        mrfzPotentials: [1, 2, 3, 4, 5],
        mrfzStars: [1, 2, 3, 4, 5, 6],
        mrfzMaterialsProbabilities: ['罕见', '小概率', '概率掉落', '固定掉落'],
        htTypes: ['N', 'R', 'SR', 'SSR'],
        htStars: [0, 1, 2, 3, 4, 5, 6],
        btStars: [3, 4, 5],
        btSteps: [1, 2, 3, 4, 5],
        btAttributes: ['风', '火', '雷', '冰', '量子', '虚数', '物理'],
        btRelicTypes: [{"code": 1, "desc": "外"}, {"code": 2, "desc": "内"}],
        btDirections: ['普攻', '秘技', '战技', '天赋', '终结技', '额外能力', '属性加成'],
        accountCategories: [{"code": 1, "desc": "支出"}, {"code": 2, "desc": "收入"}],
        permissionRecordType: [{"code": 1, "desc": "申请记录"}, {"code": 2, "desc": "审批记录"}]
    },

    mutations: {
        INIT_ROLE(state, role) {
            state.currentRole = role;
        },
    },


    actions: {}

});

export default store;