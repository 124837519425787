<template>
  <div>
    <div>
      <div>
        <el-input
            style="width: 200px;margin-right: 20px"
            size="mini"
            placeholder="地区名称"
            v-model="searchArea.name"
            clearable
            @keydown.enter.native="searchArea.currentPage = 1;searchArea.size = 10 ;initAreas()"
        ></el-input>
        <el-select
            v-model="searchArea.countryId"
            size="mini"
            style="width: 200px;margin-right: 20px"
            placeholder="所属国家"
            clearable
            filterable
            @change="searchArea.currentPage = 1;searchArea.size = 10 ;initAreas()"
        >
          <el-option
              v-for="item in countries"
              :key="item.id"
              :label="item.name"
              :value="item.id"
          >
          </el-option>
        </el-select>
        <el-select
            v-model="searchArea.type"
            size="mini"
            style="width: 200px;margin-right: 20px"
            placeholder="是否首都"
            clearable
            filterable
            @change="searchArea.currentPage = 1;searchArea.size = 10 ;initAreas()"
        >
          <el-option label="是首都" :value="1">是首都</el-option>
          <el-option label="不是首都" :value="2">不是首部</el-option>
        </el-select>
        <el-button icon="el-icon-delete" type="warning" size="mini"
                   @click="searchArea.name = '';searchArea.countryId = null;searchArea.type = null;searchArea.isCapital = null;">
          重置
        </el-button>
        <el-button
            type="success"
            size="mini"
            icon="el-icon-search"
            @click="searchArea.currentPage = 1;searchArea.size = 10 ;initAreas()"
        >
          搜索
        </el-button>
        <el-button
            type="primary"
            size="mini"
            icon="el-icon-plus"
            @click="showAddAreaView"
            v-if="role.buttonLevelComponents !== null && role.buttonLevelComponents.indexOf('Area') !== -1"
        >
          添加地区
        </el-button>
      </div>
    </div>
    <div style="margin-top: 10px">
      <el-table
          size="mini"
          :data="areas"
          border
          stripe
          :row-class-name="tableRowClassName"
          v-loading="loading"
          element-loading-text="拼命加载中"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.8)"
      >
        <el-table-column
            prop="name"
            label="地区名称"
        >
        </el-table-column>
        <el-table-column
            prop="countryName"
            label="所属国家名称"
        >
        </el-table-column>
        <el-table-column label="是否为首都" align="center">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.isCapital" type="primary">是</el-tag>
            <el-tag v-else type="error">否</el-tag>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div style="display: flex; justify-content: flex-end; margin-top: 5px">
      <el-pagination
          background
          layout="sizes, prev, pager, next, jumper, ->, total, slot"
          :current-page="searchArea.currentPage"
          :page-size="searchArea.size"
          :total="total"
          @current-change="currentChange"
          @size-change="sizeChange"
      >
      </el-pagination>
    </div>

  </div>
</template>

<script>
export default {
  name: "Area",
  data() {
    return {
      areas: [],
      countries: [],
      loading: false,
      total: 0,
      searchArea: {
        name: "",
        countryId: null,
        type: null,
        isCapital: null,
        currentPage: 1,
        size: 10,
      },
    }
  },
  mounted() {
    this.initAreas();
    this.initCountries();
  },
  computed: {
    role() {
      return this.$store.state.currentRole;
    }
  },
  methods: {
    tableRowClassName({row, rowIndex}) {
      if (rowIndex % 2 !== 0) {
        return 'warning-row';
      } else if (rowIndex % 2 === 0) {
        return 'success-row';
      }
      return '';
    },
    initAreas() {
      this.loading = true;
      console.log(this.searchArea.type);
      if (this.searchArea.type) {
        if (this.searchArea.type === 1) {
          this.searchArea.isCapital = true;
        }
        if (this.searchArea.type === 2) {
          this.searchArea.isCapital = false;
        }
      }
      this.postRequest("/xrg/area/getPageData", this.searchArea).then(resp => {
        this.loading = false;
        if (resp) {
          this.areas = resp.data;
          this.total = resp.total;
        }
      })
    },
    currentChange(page) {
      this.searchArea.currentPage = page;
      this.initAreas();
    },
    sizeChange(size) {
      this.searchArea.size = size;
      this.initAreas();
    },
    initCountries() {
      this.getRequest("/xrg/area/countries").then(resp => {
        if (resp) {
          this.countries = resp;
        }
      })
    },
    showUpdateAreaView(data) {
      // 拷贝
      this.area = Object.assign({}, data);
      this.dialogVisible = true;
      this.title = "编辑地区";
    },
  }
}
</script>

<style>
.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}

label {
  display: inline-block;
  width: 100px;
  text-align: right;
}
</style>