<template>
  <div>
    <el-image
        style="width: 800px; height: 800px"
        :src="mapSrc"
        :preview-src-list="[mapSrc]"
    >
      <template #error>
        <div
            class="image-slot"
            style="text-align: center; line-height: 49px; border: 1px solid #ccc;"
        >
          <i class="el-icon-picture-outline"></i>
        </div>
      </template>
    </el-image>
  </div>
</template>

<script>

export default {
  name: "Map",
  data() {
    return {
      mapSrc: require('../../assets/images/xrg-map.png')
    }
  }
}
</script>

<style>
</style>