import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Login from "../views/Login";
import Role from "@/views/sys/Role";
import Level from "@/views/sys/Level";
import XrgRole from "../views/xrg/Role";
import XrgLevel from "../views/xrg/Level";
import XrgCountry from "../views/xrg/Country";
import XrgArea from "../views/xrg/Area";
import XrgEcharts from "../views/xrg/XrgEcharts";
import XrgMap from "../views/xrg/Map";
import YsRole from "../views/ys/YsRole";
import YsArms from "../views/ys/YsArms";
import YsHolyRelic from "../views/ys/YsHolyRelic";
import YsEcharts from "../views/ys/YsEcharts";
import YsMaterials from "../views/ys/YsMaterials";
import FriendEcharts from "../views/record/FriendEcharts";
import Friend from "../views/record/Friend";
import Novel from "../views/record/Novel";
import Anime from "../views/record/Anime";
import DayRecord from "../views/work/DayRecord";
import Game from "../views/record/Game";
import Note from "../views/work/Note";
import Luckdraw from "../views/activity/Luckdraw";
import Permiss from "../views/sys/Permiss";
import Menu from "../views/sys/Menu";
import Pro from "../views/sys/Pro";
import Version from "../views/sys/Version";
import MessageNotification from "../views/sys/MessageNotification";
import MrfzEcharts from "@/views/mrfz/MrfzEcharts";
import MrfzRole from "@/views/mrfz/MrfzRole";
import MrfzPosition from "@/views/mrfz/MrfzPosition";
import MrfzModule from "@/views/mrfz/MrfzModule";
import MrfzMaterials from "@/views/mrfz/MrfzMaterials";
import MrfzArea from "@/views/mrfz/MrfzArea";
import UserInfo from "@/views/UserInfo";
import Files from "@/views/other/Files";
import Mail from "@/views/work/Mail.vue";
import AccountEcharts from "@/views/gr/AccountEcharts.vue";
import AccountRecord from "@/views/gr/AccountRecord.vue";
import GrNote from "@/views/gr/GrNote.vue";
import GrPass from "@/views/gr/GrPass.vue";
import GrMessageNotification from "@/views/gr/GrMessageNotification.vue";
import Translate from "@/views/work/Translate.vue";
import Music from "@/views/record/Music.vue";
import Query from "@/views/other/Query.vue";
import Im from "@/views/other/Im.vue";
import Weather from "@/views/other/Weather.vue";
import HtEquipped from "@/views/ht/HtEquipped.vue";
import HtRole from "@/views/ht/HtRole.vue";
import HtWill from "@/views/ht/HtWill.vue"
import BtDestiny from "@/views/bt/BtDestiny.vue";
import BtLightAwl from "@/views/bt/BtLightAwl.vue";
import BtMaterials from "@/views/bt/BtMaterials.vue";
import BtRelic from "@/views/bt/BtRelic.vue";
import BtRole from "@/views/bt/BtRole.vue";
import BtTrack from "@/views/bt/BtTrack.vue";
import BtEcharts from "@/views/bt/BtEcharts.vue";
import McRecord from "@/views/mc/McRecord.vue";
import PermissionRelevance from "@/views/permission/PermissionRelevance.vue";
import PermissionRecord from "@/views/permission/PermissionRecord.vue";

Vue.use(VueRouter);

//解决编程式路由往同一地址跳转时会报错的情况
const originalPush = VueRouter.prototype.push;
const originalReplace = VueRouter.prototype.replace;
//push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch((err) => err);
};
//replace
VueRouter.prototype.replace = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalReplace.call(this, location, onResolve, onReject);
  return originalReplace.call(this, location).catch((err) => err);
};

const routes = [
  {
    path: "/",
    name: "Login",
    component: Login,
    hidden: true
  },
  {
    path: "/home",
    name: "用户管理",
    component: Home,
    children: [
      {
        path: "/sys/role",
        name: "角色列表",
        component: Role
      },
      {
        path: "/sys/level",
        name: "等级列表",
        component: Level
      },
    ]
  },
  {
    path: "/home",
    name: "个人管理",
    component: Home,
    children: [
      {
        path: "/gr/accountRecord",
        name: "账单列表",
        component: AccountRecord
      },
      {
        path: "/gr/accountEcharts",
        name: "账单图表",
        component: AccountEcharts
      },
      {
        path: "/gr/note",
        name: "笔记列表",
        component: GrNote
      },
      {
        path: "/gr/pass",
        name: "密码记录",
        component: GrPass
      },
      {
        path: "/gr/messageNotification",
        name: "消息记录",
        component: GrMessageNotification
      },
    ]
  },
  {
    path: "/home",
    name: "小人国",
    component: Home,
    children: [
      {
        path: "/xrg/role",
        name: "角色列表",
        component: XrgRole
      },
      {
        path: "/xrg/level",
        name: "等级列表",
        component: XrgLevel
      },
      {
        path: "/xrg/country",
        name: "国家列表",
        component: XrgCountry
      },
      {
        path: "/xrg/area",
        name: "地区列表",
        component: XrgArea
      },
      {
        path: "/xrg/xrgEcharts",
        name: "xrg图表",
        component: XrgEcharts
      },
      {
        path: "/xrg/map",
        name: "xrg地图",
        component: XrgMap
      },
    ]
  },
  {
    path: "/home",
    name: "记录管理",
    component: Home,
    children: [
      {
        path: "/record/friendEcharts",
        name: "朋友图表",
        component: FriendEcharts
      },
      {
        path: "/record/friend",
        name: "朋友列表",
        component: Friend
      },
      {
        path: "/record/novel",
        name: "书籍列表",
        component: Novel
      },
      {
        path: "/record/anime",
        name: "番剧列表",
        component: Anime
      },
      {
        path: "/record/game",
        name: "游戏列表",
        component: Game
      },
      {
        path: "/record/music",
        name: "音乐列表",
        component: Music
      },
    ]
  },
  {
    path: "/home",
    name: "常用管理",
    component: Home,
    children: [
      {
        path: "/work/dayRecord",
        name: "每日记录列表",
        component: DayRecord
      },
      {
        path: "/work/note",
        name: "笔记列表",
        component: Note
      },
      {
        path: "/work/mail",
        name: "邮件列表",
        component: Mail
      },
      {
        path: "/work/translate",
        name: "文本翻译",
        component: Translate
      },
    ]
  },
  {
    path: "/home",
    name: "活动管理",
    component: Home,
    children: [
      {
        path: "/activity/luckdraw",
        name: "抽奖活动",
        component: Luckdraw
      },
    ]
  },
  {
    path: "/home",
    name: "审批管理",
    component: Home,
    children: [
      {
        path: "/permission/permissionRelevance",
        name: "审批关联",
        component: PermissionRelevance
      },
      {
        path: "/permission/permissionRecord",
        name: "审批记录",
        component: PermissionRecord
      },
    ]
  },
  {
    path: "/home",
    name: "系统管理",
    component: Home,
    children: [
      {
        path: "/sys/permiss",
        name: "权限组管理",
        component: Permiss
      },
      {
        path: "/sys/menu",
        name: "菜单管理",
        component: Menu
      },
      {
        path: "/sys/pro",
        name: "系统程序",
        component: Pro
      },
      {
        path: "/sys/version",
        name: "版本管理",
        component: Version
      },
      {
        path: "/sys/messageNotification",
        name: "消息推送",
        component: MessageNotification
      },
    ]
  },
  {
    path: "/home",
    name: "原神管理",
    component: Home,
    children: [
      {
        path: "/ys/ysEcharts",
        name: "ys角色图表",
        component: YsEcharts
      },
      {
        path: "/ys/ysRole",
        name: "ys角色列表",
        component: YsRole
      },
      {
        path: "/ys/ysArms",
        name: "ys武器列表",
        component: YsArms
      },
      {
        path: "/ys/ysHolyRelic",
        name: "ys圣遗物列表",
        component: YsHolyRelic
      },
      {
        path: "/ys/ysMaterials",
        name: "ys材料列表",
        component: YsMaterials
      },
    ]
  },
  {
    path: "/home",
    name: "方舟管理",
    component: Home,
    children: [
      {
        path: "/mrfz/mrfzEcharts",
        name: "明日方舟角色图表",
        component: MrfzEcharts
      },
      {
        path: "/mrfz/mrfzRole",
        name: "明日方舟角色列表",
        component: MrfzRole
      },
      {
        path: "/mrfz/mrfzPosition",
        name: "明日方舟定位列表",
        component: MrfzPosition
      },
      {
        path: "/mrfz/mrfzModule",
        name: "明日方舟模组列表",
        component: MrfzModule
      },
      {
        path: "/mrfz/mrfzMaterials",
        name: "明日方舟材料列表",
        component: MrfzMaterials
      },
      {
        path: "/mrfz/mrfzArea",
        name: "明日方舟地区列表",
        component: MrfzArea
      },
    ]
  },
  {
    path: "/home",
    name: "崩铁管理",
    component: Home,
    children: [
      {
        path: "/bt/btDestiny",
        name: "命途列表",
        component: BtDestiny
      },
      {
        path: "/bt/btLightAwl",
        name: "光锥列表",
        component: BtLightAwl
      },
      {
        path: "/bt/btMaterials",
        name: "材料列表",
        component: BtMaterials
      },
      {
        path: "/bt/btRelic",
        name: "遗器列表",
        component: BtRelic
      },
      {
        path: "/bt/btRole",
        name: "角色列表",
        component: BtRole
      },
      {
        path: "/bt/btTrack",
        name: "行迹列表",
        component: BtTrack
      },
      {
        path: "/bt/btEcharts",
        name: "角色图表",
        component: BtEcharts
      },
    ]
  },
  {
    path: "/home",
    name: "MC管理",
    component: Home,
    children: [
      {
        path: "/mc/mcRecord",
        name: "MC记录",
        component: McRecord
      },
    ]
  },
  {
    path: "/home",
    name: "通用管理",
    component: Home,
    children: [
      {
        path: "/other/files",
        name: "文件列表",
        component: Files
      },
      {
        path: "/other/query",
        name: "openai查询",
        component: Query
      },
      {
        path: "/other/im",
        name: "聊天社区",
        component: Im
      },
      {
        path: "/other/weather",
        name: "天气服务",
        component: Weather
      },
    ]
  },
  {
    path: "/home",
    name: "幻塔管理",
    component: Home,
    children: [
      {
        path: "/ht/htEquipped",
        name: "装备列表",
        component: HtEquipped
      },
      {
        path: "/ht/htRole",
        name: "拟态武器",
        component: HtRole
      },
      {
        path: "/ht/htWill",
        name: "意志列表",
        component: HtWill
      },
    ]
  },
  {
    path: "/home",
    name: "个人中心",
    component: Home,
    children: [
      {
        path: "/userInfo",
        name: "个人中心",
        component: UserInfo
      },
    ]
  },
];

const router = new VueRouter({
  routes,
});

export default router;
