<template xmlns="http://www.w3.org/1999/html">
  <div>
    <!-- 查询条件 -->
    <div>
      <el-input
          size="mini"
          style="width: 200px;margin-right: 20px"
          v-model="cityParams.location"
          clearable
          placeholder="请输入地区名称"
          @keydown.enter.native="getCityInfo"
      ></el-input>
      <el-button
          type="success"
          size="mini"
          style="margin-right: 20px"
          icon="el-icon-search"
          @click="getCityInfo"
      >
        城市信息
      </el-button>
      <el-select
          v-model="qryParams.location"
          size="mini"
          style="width: 200px;margin-right: 20px"
          placeholder="请选择地区"
          :disabled="selDisabled"
          clearable
          filterable
      >
        <el-option
            v-for="item in cityData"
            :key="item.id"
            :label="item.name"
            :value="item.id"
        >
        </el-option>
      </el-select>
      <el-button
          type="primary"
          size="mini"
          icon="el-icon-search"
          :disabled="selDisabled"
          @click="getSevenDayWeatherInfo"
      >
        7天天气预报
      </el-button>
      <el-button
          type="info"
          size="mini"
          icon="el-icon-search"
          :disabled="selDisabled"
          @click="getAirQualityInfo"
      >
        当天空气质量
      </el-button>
      <el-button
          type="danger"
          size="mini"
          icon="el-icon-search"
          :disabled="selDisabled"
          @click="getWarningInfo"
      >
        天气预警信息
      </el-button>
    </div>

    <div style="margin-top: 10px">
      <el-select
          v-model="qryParams.type"
          size="mini"
          style="width: 200px;margin-right: 20px"
          placeholder="请选择天气指数类型"
          :disabled="selDisabled"
          clearable
          filterable
      >
        <el-option
            v-for="item in weatherIndexTypes"
            :key="item.code"
            :label="item.desc"
            :value="item.code"
        >
        </el-option>
      </el-select>
      <el-button
          type="warning"
          size="mini"
          icon="el-icon-search"
          :disabled="selDisabled"
          @click="getWeatherIndexInfo"
      >
        当天天气指数
      </el-button>
    </div>

    <!-- 7天天气预报 -->
    <div style="width: 100%">
      <div style="margin-left: 10px; margin-top: 15px" v-if="weatherInfo.fxLink">
        <a :href="weatherInfo.fxLink" target="_blank">7天和风天气网址</a>
      </div>
      <el-row :gutter="12">
        <el-col :span="6" v-for="(item, index) in weatherInfo.daily" :key="index" style="margin-top: 15px">
          <el-card shadow="hover">
            <pre>日期: {{item.fxDate}}</pre>
            <pre>白天天气: <i :class="'qi-' + item.iconDay"></i> {{item.textDay}}</pre>
            <pre>晚上天气: <i :class="'qi-' + item.iconNight"></i> {{item.textNight}}</pre>
            <pre>温度: {{item.tempMin}}° =>  {{item.tempMax}}°</pre>
            <pre>日出日落: {{item.sunrise}} =>  {{item.sunset}}</pre>
            <pre>月出月落: {{item.moonrise}}° =>  {{item.moonset}}</pre>
            <pre>月相: <i :class="'qi-' + item.moonPhaseIcon"></i> {{item.moonPhase}}</pre>
            <pre>当天总降水量: {{item.precip}}</pre>
            <pre>紫外线强度指数: {{item.uvIndex}}</pre>
            <pre>相对湿度: {{item.humidity}}%</pre>
            <pre>大气压强(百帕): {{item.pressure}}</pre>
            <pre>能见度: {{item.vis}}公里</pre>

            <el-tooltip class="item" effect="dark" placement="right-end">
              <div slot="content">
                <pre>白天风向360角度: {{item.wind360Day}}</pre>
                <pre>白天风向: {{item.windDirDay}}</pre>
                <pre>白天风力等级: {{item.windScaleDay}}</pre>
                <pre>白天风速: {{item.windSpeedDay}}公里/小时</pre>
                <pre>晚上风向360角度: {{item.wind360Night}}</pre>
                <pre>晚上风向: {{item.windDirNight}}</pre>
                <pre>晚上风力等级: {{item.windScaleNight}}</pre>
                <pre>晚上风速: {{item.windSpeedNight}}公里/小时</pre>
              </div>
              <el-tag type="primary">风向信息 (鼠标移动到上面查看)</el-tag>
            </el-tooltip>
          </el-card>
        </el-col>
      </el-row>
    </div>

    <!-- 空气质量 -->
    <div style="width: 100%">
      <div style="margin-left: 10px; margin-top: 15px" v-if="airQualityInfo.fxLink">
        <a :href="airQualityInfo.fxLink" target="_blank">空气质量网址</a>
      </div>
      <el-row :gutter="12">
        <el-col :span="12" v-if="airQualityInfo.now" style="margin-top: 15px">
          <el-card shadow="hover">
            <pre>发布时间: {{airQualityInfo.now.pubTime}}</pre>
            <pre>空气质量指数: {{airQualityInfo.now.aqi}}</pre>
            <pre>空气质量指数等级: {{airQualityInfo.now.level}}</pre>
            <pre>空气质量指数级别: {{airQualityInfo.now.category}}</pre>
            <pre>空气质量的主要污染物(优-NA): {{airQualityInfo.now.primary}}</pre>
            <pre>PM10: {{airQualityInfo.now.pm10}}</pre>
            <pre>PM2.5: {{airQualityInfo.now.pm2p5}}</pre>
            <pre>二氧化氮: {{airQualityInfo.now.no2}}</pre>
            <pre>二氧化硫: {{airQualityInfo.now.so2}}</pre>
            <pre>一氧化碳: {{airQualityInfo.now.co}}</pre>
            <pre>臭氧: {{airQualityInfo.now.o3}}</pre>
          </el-card>
        </el-col>
      </el-row>

    </div>

    <!-- 天气指数 -->
    <div style="width: 100%">
      <div style="margin-left: 10px; margin-top: 15px" v-if="weatherIndexInfo.fxLink">
        <a :href="weatherIndexInfo.fxLink" target="_blank">天气指数网址</a>
      </div>
      <el-row :gutter="12">
        <el-col :span="8" v-for="(item, index) in weatherIndexInfo.daily" :key="index" style="margin-top: 15px">
          <el-card shadow="hover">
            <p>
              <span style="font-weight: bolder;">预报日期: </span>
              <span style="color: #db6fd2">{{item.date}}</span>
            </p>
            <p>
              <span style="font-weight: bolder;">生活指数类型名称: </span>
              <span style="color: #db6fd2">{{item.name}}</span>
            </p>
            <p>
              <span style="font-weight: bolder;">生活指数预报等级: </span>
              <span style="color: #db6fd2">{{item.level}}</span>
            </p>
            <p>
              <span style="font-weight: bolder;">生活指数预报级别名称: </span>
              <span style="color: #db6fd2">{{item.category}}</span>
            </p>
            <p>
              <span style="font-weight: bolder;">详细描述: </span>
              <span style="color: #db6fd2">{{item.text}}</span>
            </p>
          </el-card>
        </el-col>
      </el-row>
    </div>

    <!-- 天气预警信息 -->
    <div style="width: 100%">
      <div style="margin-left: 10px; margin-top: 15px" v-if="weatherWarningInfo.fxLink">
        <a :href="weatherWarningInfo.fxLink" target="_blank">天气预警网址</a>
      </div>
      <el-row :gutter="12">
        <el-col :span="8" v-for="(item, index) in weatherWarningInfo.warning" :key="index" style="margin-top: 15px">
          <el-card shadow="hover">
            <p>
              <span style="font-weight: bolder;">发布时间: </span>
              <span style="color: #db6fd2">{{item.pubTime}}</span>
            </p>
            <p>
              <span style="font-weight: bolder;">预警发布单位: </span>
              <span style="color: #db6fd2">{{item.sender}}</span>
            </p>
            <p>
              <span style="font-weight: bolder;">标题: </span>
              <span style="color: #db6fd2">{{item.title}}</span>
            </p>
            <p>
              <span style="font-weight: bolder;">预警开始时间: </span>
              <span style="color: #db6fd2">{{item.startTime}}</span>
            </p>
            <p>
              <span style="font-weight: bolder;">预警开始时间: </span>
              <span style="color: #db6fd2">{{item.endTime}}</span>
            </p>
            <p>
              <span style="font-weight: bolder;">发布状态: </span>
              <span style="color: #db6fd2">{{item.status}}</span>
            </p>
            <p>
              <span style="font-weight: bolder;">严重等级: </span>
              <span style="color: #db6fd2">{{item.severity}}</span>
            </p>
            <p>
              <span style="font-weight: bolder;">预警类型名称: </span>
              <span style="color: #db6fd2">{{item.typeName}}</span>
            </p>
            <p>
              <span style="font-weight: bolder;">紧迫程度: </span>
              <span style="color: #db6fd2">{{item.urgency}}</span>
            </p>
            <p>
              <span style="font-weight: bolder;">确定性: </span>
              <span style="color: #db6fd2">{{item.certainty}}</span>
            </p>
            <p>
              <span style="font-weight: bolder;">文字描述: </span>
              <span style="color: #db6fd2">{{item.text}}</span>
            </p>
          </el-card>
        </el-col>
      </el-row>
    </div>

  </div>
</template>

<script>
export default {
  name: "Weather",
  data() {
    return {
      cityParams: {
        location: "",
        adm: ""
      },
      qryParams: {
        location: "",
        type: null
      },
      selDisabled: true,
      cityData: [],
      weatherInfo: {},
      airQualityInfo: {},
      weatherIndexTypes: [],
      weatherIndexInfo: {},
      weatherWarningInfo: {}
    }
  },
  mounted() {
    this.getWeatherIndexTypes();
  },
  methods: {
    getCityInfo() {
      this.cityData = [];
      this.selDisabled = true;
      this.postRequest("/weather/searchCityInfo", this.cityParams).then(resp => {
        if (resp) {
          this.cityData = resp;
          this.selDisabled = false;
        }
      })
    },
    getSevenDayWeatherInfo() {
      // 清空其他数据
      this.airQualityInfo = {};
      this.weatherIndexInfo = {};
      this.weatherWarningInfo = {};
      this.postRequest("/weather/searchSevenDayWeatherInfo", this.qryParams).then(resp => {
        if (resp) {
          this.weatherInfo = resp;
        }
      })
    },
    getAirQualityInfo() {
      // 清空其他数据
      this.weatherInfo = {};
      this.weatherIndexInfo = {};
      this.weatherWarningInfo = {};
      this.postRequest("/weather/searchAirQualityInfo", this.qryParams).then(resp => {
        if (resp) {
          this.airQualityInfo = resp;
        }
      })
    },
    getWarningInfo() {
      // 清空其他数据
      this.airQualityInfo = {};
      this.weatherIndexInfo = {};
      this.weatherInfo = {};
      this.postRequest("/weather/searchWeatherWarningInfo", this.qryParams).then(resp => {
        if (resp) {
          this.weatherWarningInfo = resp;
        }
      })
    },
    getWeatherIndexTypes() {
      this.getRequest("/weather/getAllWeatherIndexTypes").then(resp => {
        this.weatherIndexTypes = resp;
      })
    },
    getWeatherIndexInfo() {
      if (this.qryParams.type === null || this.qryParams.type === undefined) {
        this.$message.error("请先选择天气指数类型");
        return;
      }
      // 清空其他数据
      this.airQualityInfo = {};
      this.weatherInfo = {};
      this.weatherWarningInfo = {};
      this.postRequest("/weather/searchWeatherIndexInfo", this.qryParams).then(resp => {
        if (resp) {
          this.weatherIndexInfo = resp;
        }
      })
    }
  }
}
</script>

<style>

</style>