<template>
  <div class="pc">
    <el-container class="up">
      <el-header class="homeHeader" style="height: 40px">
        <div class="title">xrg</div>
        <div>
          <el-dropdown class="userInfo" @command="commandHandler">
            <span class="el-dropdown-link">
              {{ role.username }}<i><img :src="initUserFace(role.userFace)" /></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="userinfo">个人中心</el-dropdown-item>
              <el-dropdown-item command="logout">注销登录</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </el-header>
      <el-container class="down">
        <el-aside :width="isCollapse ? '65px' : '210px'" style="background-color: #d1dfe5; height: calc(100vh - 40px)">
          <!-- <div class="toggle-button" @click="toggleCollapse" unselectable="on" onselectstart="return false;" style="-moz-user-select:none;">
            |||
          </div> -->
          <el-menu
              router
              unique-opened
              :collapse="isCollapse"
              :collapse-transition="false"
              :default-active="$route.path"
              background-color="#d1dfe5"
              active-text-color="#db6fd2"
              @select="handleSelect">
            <el-submenu
                :index="index + ''"
                v-for="(item, index) in menuList"
                :key="index"
            >
              <template slot="title">
                <i
                    :class="item.iconCls"
                    style="color: #1accff; margin-right: 5px"
                ></i>
                <span>{{ item.name }}</span>
              </template>
              <el-menu-item
                  :index="children.path"
                  v-for="(children, indexj) in item.children"
                  :key="indexj"
                  @click="addTab(children)"
              >
                {{ children.name }}
              </el-menu-item>
            </el-submenu>
          </el-menu>
        </el-aside>
        <!-- style="background-color: #d2e8d1" -->
        <el-main style="height: calc(100vh - 40px);">
          <!-- 面包屑 -->
          <div 
              class="toggle-button" 
              @click="toggleCollapse" 
              unselectable="on" 
              onselectstart="return false;" 
              style="-moz-user-select:none; float: left; margin-right: 10px"
              v-html="'<<<'"
            >
          </div>
          <el-breadcrumb
              separator-class="el-icon-arrow-right"
          >
            <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item v-if="this.$router.currentRoute.path !== '/home' && this.$router.currentRoute.matched[0]">{{ this.$router.currentRoute.matched[0].name }}</el-breadcrumb-item>
            <el-breadcrumb-item v-if="this.$router.currentRoute.path !== '/home'">{{ this.$router.currentRoute.name }}</el-breadcrumb-item>
          </el-breadcrumb>

          <div class="tabsClass">
            <el-button style="margin-top: 5px" v-if="editableTabs.length >= 1" @click="editableTabs = []">清除Tag</el-button>
            <el-tag
              closable
              size="medium"
              style="margin-left: 5px; margin-top: 5px"
              v-for="(item, index) in editableTabs"
              :key="index"
              :disable-transitions="true"
              :label="item.title"
              :name="item.name"
              :effect="$route.path === item.path ? 'dark' : 'plain'"
              @click="handleClick(item)"
              @close="handleClose(item, index)"
            >
              {{item.title}}
            </el-tag>
          </div>

          <div
              class="homeWelcome"
              v-if="this.$router.currentRoute.path === '/home'"
          >
            欢迎来到duiduo管理系统
            <div>
              <img style="width: 400px; height: 400px; border-radius: 50px; margin-top: 25px" src="../assets/logo.png">
            </div>
          </div>
          <router-view class="homeRouterWiew"/>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
export default {
  name: 'Home',
  data() {
    return {
      menuList: [],
      editableTabs: [],
      activeIndex: '',
      isCollapse: false
    }
  },
  computed: {
    role() {
      return this.$store.state.currentRole;
    }
  },
  created() {
    this.getMenuList();
  },
  // 监听路由跳转
  watch: {
    $route() {
      this.handleSelect(this.activeIndex)
    }
  },
  methods: {
    commandHandler(command) {
      if (command === "logout") {
        this.$confirm("此操作将注销登录, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
            .then(() => {
              // 注销登录
              this.postRequest("/logout");
              // 清空用户信息
              window.sessionStorage.removeItem("tokenStr");
              window.sessionStorage.removeItem("role");
              // 跳转登录页
              this.$router.replace("/");
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消",
              });
            });
      }
      if (command === "userinfo") {
        this.$router.push('/userinfo');
      }
    },
    getMenuList() {
      this.getRequest("/menu").then(resp => {
        this.menuList = resp;
        // 初始化Tab
        this.initEditableTabs();
      })
    },
    toggleCollapse() {
      this.isCollapse = !this.isCollapse;
    },
    initUserFace(image) {
      return window.server.apiBase + "/files?filePath=" + image + "&name=";
    },
    addTab(val) {
      let pushFlag = true;
      // 存在不新加
      this.editableTabs.forEach((tab, index) => {
        if (tab.name === val.component) {
          pushFlag = false;
        }
      });
      if (pushFlag) {
        this.editableTabs.push({
          title: val.name,
          name: val.component,
          path: val.path
        });
      }
    },
    handleClick(val) {
      this.$router.push(val.path);
    },
    handleClose(val, index) {
      let tabs = this.editableTabs;
      if (tabs.length === 1) { // 最后一页 去往首页
        this.$router.push('/home');
      }
      tabs.forEach((tab, index) => {
        if (tab.name === val.name) {
          let nextTab = tabs[index + 1] || tabs[index - 1];
          if (nextTab) {
            if (this.$router.currentRoute.path === val.path) { // 说明关闭的是当前页面
              this.$router.push(nextTab.path);
            }
          }
        }
      });
      this.editableTabs = this.editableTabs.filter(tab => tab.name !== val.name);
    },
    // 菜单激活回调
    handleSelect(index) {
      this.activeIndex = index;
    },
    initEditableTabs() {
      this.editableTabs = [];
      let path = this.$router.currentRoute.path;
      let name = this.$router.currentRoute.name;

      // 如果不是主页 默认追加一条
      if (String(path) !== "/home") {
        // 获取component组件信息
        let component = "";
        this.menuList.forEach((val, index) => {
          val.children.forEach((rval, index) => {
            if (String(name) === String(rval.name)) {
              component = rval.component;
            }
          });
        });

        this.editableTabs.push({
          title: name,
          name: component,
          path: path
        });
      }
    }
  }
}
</script>

<style>
/* 配合 global.css 调整最大边距*/
.pc,
.up {
  height: 100%;
}

.el-aside .el-menu {
    border-right: none;
}

/* .toggle-button {
  background-color: #c3c5dd;
  font-size: 10px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.2em;
  cursor: pointer;
} */

.toggle-button {
  font-size: 12px;
  line-height: 12px;
  text-align: center;
  letter-spacing: 0.2em;
  cursor: pointer;
}

.homeHeader {
  background: #FF69B4;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
  box-sizing: border-box;
  height: 100%;
}

.homeHeader .title {
  font-size: 20px;
  font-family: 华文琥珀;
  color: white;
}

.homeHeader .userInfo {
  cursor: pointer;
}

.el-dropdown-link img {
  width: 28px;
  height: 28px;
  border-radius: 14px;
  margin-left: 8px;
  margin-top: 5px;
}

.homeWelcome {
  text-align: center;
  font-size: 30px;
  font-family: 华文琥珀;
  color: #FF69B4;
  padding-top: 50px;
}

.tabsClass {
  padding-top: 25px;
  cursor: pointer;
}

.tabsClass .el-button {
  margin-right: 20px;
}

.homeRouterWiew {
  margin-top: 10px;
}
</style>
