<template>
  <div>


    <div>
      <div>
        <el-input
            style="width: 200px;margin-right: 20px"
            size="mini"
            placeholder="记录内容"
            v-model="searchRecord.content"
            clearable
            @keydown.enter.native="searchRecord.currentPage = 1; searchRecord.size = 10; initPageData()"
        ></el-input>
        <el-select
            v-model="searchRecord.type"
            size="mini"
            style="width: 200px;margin-right: 20px"
            placeholder="类型"
            filterable
            @change="searchRecord.currentPage = 1; searchRecord.size = 10; initPageData()"
        >
          <el-option
              v-for="item in permissionRecordType"
              :key="item.code"
              :label="item.desc"
              :value="item.code"
          >
          </el-option>
        </el-select>
        <el-select
            v-model="searchRecord.result"
            size="mini"
            style="width: 200px;margin-right: 20px"
            placeholder="审批结果"
            clearable
            filterable
            @change="searchRecord.currentPage = 1; searchRecord.size = 10; initPageData()"
        >
          <el-option label="未审批" value="0"></el-option>
          <el-option label="通过" value="1"></el-option>
          <el-option label="不通过" value="2"></el-option>
        </el-select>
        <el-button icon="el-icon-delete" type="warning" size="mini" @click="searchRecord.content = ''; searchRecord.type = 1; searchRecord.result = null">
          重置
        </el-button>
        <el-button
            type="success"
            size="mini"
            icon="el-icon-search"
            @click="searchRecord.currentPage = 1; searchRecord.size = 10; initPageData()"
        >
          搜索
        </el-button>
        <el-button
            type="primary"
            size="mini"
            icon="el-icon-plus"
            @click="showApplyRecordView"
        >
          添加申请
        </el-button>
      </div>
    </div>

    <div style="margin-top: 10px">
      <el-table
          size="mini"
          :data="data"
          border
          stripe
          :row-class-name="tableRowClassName"
          v-loading="loading"
          element-loading-text="拼命加载中"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.8)"
      >
        <el-table-column label="申请内容" align="center" width="330">
          <template slot-scope="scope">
            <el-tooltip v-if="scope.row.content" class="item" effect="dark" placement="right-end">
              <span v-html="scope.row.content.replace(/\n/gm, '<br>')" slot="content">{{scope.row.content}}</span>
              <!--<span>{{scope.row.context | ellipsis}}</span>-->
              <el-tag type="info">鼠标移动到上面查看</el-tag>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column
            label="申请人"
            width="200"
            align="center"
            prop="roleName"
        ></el-table-column>
        <el-table-column
            label="审批结果"
            width="100"
            align="center"
        >
          <template slot-scope="scope">
            <el-tag v-if="scope.row.result === 0" type="primary">未审批</el-tag>
            <el-tag v-else-if="scope.row.result === 1" type="success">通过</el-tag>
            <el-tag v-else-if="scope.row.result === 2" type="danger">不通过</el-tag>
            <el-tag v-else type="info">-</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="审批评论" align="center" width="330">
          <template slot-scope="scope">
            <el-tooltip v-if="scope.row.remark" class="item" effect="dark" placement="right-end">
              <span v-html="scope.row.remark.replace(/\n/gm, '<br>')" slot="content">{{scope.row.remark}}</span>
              <!--<span>{{scope.row.context | ellipsis}}</span>-->
              <el-tag type="info">鼠标移动到上面查看</el-tag>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column
            label="审批人"
            width="200"
            align="center"
            prop="parentRoleName"
        ></el-table-column>
        <el-table-column
            label="申请时间"
            width="150"
            sortable
            align="center"
            prop="createTime"
        ></el-table-column>
        <el-table-column
            label="审批时间"
            width="150"
            sortable
            align="center"
            prop="updateTime"
        ></el-table-column>
        <el-table-column
            label="操作"
            fixed="right"
            width="100"
        >
          <template slot-scope="scope">
            <el-button v-if="searchRecord.type === 1" size="mini" style="padding: 3px" type="danger" @click="deleteRecord(scope.row)">删除</el-button>
            <el-button v-if="searchRecord.type === 2 && scope.row.result === 0" size="mini" style="padding: 3px" type="warning" @click="showPerssionView(scope.row)">审批</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div style="display: flex; justify-content: flex-end; margin-top: 5px">
      <el-pagination
          background
          layout="sizes, prev, pager, next, jumper, ->, total, slot"
          :current-page="searchRecord.currentPage"
          :page-size="searchRecord.size"
          :total="total"
          @current-change="currentChange"
          @size-change="sizeChange"
      >
      </el-pagination>
    </div>

    <!-- 申请页面 -->
    <el-dialog
        :close-on-click-modal="false"
        :title="title"
        @close="applyCloseDialog()"
        :visible.sync="applyDialogVisible"
        width="800px">
      <el-form ref="applyRecordForm" :model="applyRecord" :rules="rules">
        <table>
          <tr>
            <el-form-item label="申请内容" prop="content">
              <el-input
                  type="textarea"
                  :rows="5"
                  size="mini"
                  style="width: 500px"
                  v-model="applyRecord.content"
                  placeholder="请输入申请内容"
              ></el-input>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="申请时间" prop="createTime">
              <el-date-picker
                  style="width: 200px"
                  v-model="applyRecord.createTime"
                  type="datetime"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  placeholder="选择日期时间"
              >
              </el-date-picker>
            </el-form-item>
          </tr>
        </table>
      </el-form>
      <span slot="footer" class="dialog-footer">
          <el-button @click="applyDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="doApply">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 审批页面 -->
    <el-dialog
        :close-on-click-modal="false"
        :title="title"
        @close="closeDialog()"
        :visible.sync="dialogVisible"
        width="800px">
      <el-form ref="recordForm" :model="record">
        <table>
          <tr>
            <el-form-item label="申请内容">
              <el-input
                  type="textarea"
                  :rows="5"
                  size="mini"
                  style="width: 500px"
                  v-model="record.content"
                  disabled
                  placeholder="请输入申请内容"
              ></el-input>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="是否通过">
              <el-switch
                  v-model="record.result"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                  :active-value="passResult"
                  :inactive-value="notPassResult"
                  style="padding-top: 6px">
              </el-switch>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="审批评论">
              <el-input
                  type="textarea"
                  :rows="5"
                  size="mini"
                  style="width: 500px"
                  v-model="record.remark"
                  placeholder="请输入审批评论"
              ></el-input>
            </el-form-item>
          </tr>
        </table>
      </el-form>
      <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="doPerssion">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "PermissionRecord",
  data() {
    return {
      loading: false,
      data: [],
      searchRecord: {
        content: '',
        type: 1,
        result: null,
        roleIds: null,
        currentPage: 1,
        size: 10,
      },
      total: 0,
      record: {
        id: null,
        content: '',
        result: null,
        roleId: null,
        remark: null,
        parentRoleId: null,
      },
      dialogVisible: false,
      title: '',
      passResult: 1,
      notPassResult: 2,
      applyDialogVisible: false,
      applyRecord: {
        content: '',
        createTime: ''
      },
      rules: {
        content: [{required: true, message: "请输入申请内容", trigger: "blur"}],
        createTime: [{required: true, message: "请选择申请时间", trigger: "change"}],
      },
    }
  },
  computed: {
    roleButton() {
      return this.$store.state.currentRole;
    },
    permissionRecordType() {
      return this.$store.state.permissionRecordType;
    }
  },
  mounted() {
    this.getCreateTime();
    this.initPageData();
  },
  methods: {
    tableRowClassName({row, rowIndex}) {
      if (rowIndex % 2 !== 0) {
        return 'warning-row';
      } else if (rowIndex % 2 === 0) {
        return 'success-row';
      }
      return '';
    },
    /**
     * 获取当前 年月日
     */
    async getCreateTime() {
      var dt = new Date();
      let year = dt.getFullYear();
      let month = (dt.getMonth() + 1).toString().padStart(2, '0');
      let date = dt.getDate().toString().padStart(2, '0');
      let hours = dt.getHours().toString().padStart(2, '0'); //获取当前时间的小时
      let minutes = dt.getMinutes().toString().padStart(2, '0'); //获取当前时间的分数
      let seconds = dt.getSeconds().toString().padStart(2, '0'); //获取当前时间的秒数

      this.applyRecord.createTime = `${year}-${month}-${date} ${hours}:${minutes}:${seconds}`;
    },
    initPageData() {
      this.loading = true;
      if (this.searchRecord.type === 2) {
        // 审批记录
        let role = JSON.parse(window.sessionStorage.getItem("role"));
        this.getRequest("/permission/permission-relevance/getRoleIdsByParentRoleId/" + role.id).then(resp => {
          this.loading = false;
          if (resp) {
            this.searchRecord.roleIds = resp;
            // 查询数据
            this.postRequest("/permission/permission-record/getPageData", this.searchRecord).then(resp => {
              if (resp) {
                this.data = resp.data;
                this.total = resp.total;
              }
            })
          }
        })
      } else {
        // 申请记录
        this.searchRecord.roleIds = null;
        // 查询数据
        this.postRequest("/permission/permission-record/getPageData", this.searchRecord).then(resp => {
          this.loading = false;
          if (resp) {
            this.data = resp.data;
            this.total = resp.total;
          }
        })
      }
    },
    currentChange(page) {
      this.searchRecord.currentPage = page;
      this.initPageData();
    },
    sizeChange(size) {
      this.searchRecord.size = size;
      this.initPageData();
    },
    showPerssionView(data) {
      // 拷贝
      this.record = JSON.parse(JSON.stringify(data));
      this.dialogVisible = true;
      this.title = "审批页面";
    },
    showApplyRecordView() {
      this.applyDialogVisible = true;
      this.title = "添加申请";
    },
    deleteRecord(data) {
      this.$confirm(
          "此操作将永久删除该记录, 是否继续?",
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
      ).then(() => {
        this.deleteRequest("/permission/permission-record/" + data.id).then(resp => {
              if (resp) {
                this.initPageData();
              }
            }
        );
      }).catch(() => {
        this.$message({
          type: "info",
          message: "已取消删除",
        });
      });
    },
    closeDialog() {
      this.$refs["recordForm"].resetFields();
    },
    applyCloseDialog() {
      this.$refs["applyRecordForm"].resetFields();
    },
    async doApply() {
      this.$refs["applyRecordForm"].validate(async (valid) => {
        let role = JSON.parse(window.sessionStorage.getItem("role"));
        // 校验有没有审批人 获取所有的审批人
        const parentRoleIds = await this.getRequest("/permission/permission-relevance/getParentRoleIdByRoleId/" + role.id);

        if (!parentRoleIds || parentRoleIds.length === 0) {
          this.$message.error("您没有审批人, 不能进行申请 (*╹▽╹*)");
        } else {
          if (valid) {
            const resp = await this.postRequest("/permission/permission-record", this.applyRecord);
            if (resp) {
              this.applyDialogVisible = false;
              this.initPageData();

              // 通知
              // 获取所有的审批人
              let data = JSON.parse(JSON.stringify(parentRoleIds));
              data.forEach((item, index) => {
                let params = {
                  type: 1,
                  title: '新申请单',
                  content: '[' + role.realname + '] 提交了一个申请，请及时审批！',
                  roleId: item
                };
                this.postRequest("/gr/message-notification", params);
              });
            }
          } else {
            console.log('error submit!!');
            return false;
          }
        }
      });
    },
    doPerssion() {
      let role = JSON.parse(window.sessionStorage.getItem("role"));
      this.record.parentRoleId = role.id;
      this.putRequest("/permission/permission-record", this.record).then(resp => {
        if (resp) {
          this.dialogVisible = false;
          this.initPageData();
          // 通知
          let params = {
            type: 1,
            title: '申请单回执',
            content: '审批人 [' + role.realname + '] 已审批您的申请，请及时查看！',
            roleId: this.record.roleId
          };
          this.postRequest("/gr/message-notification", params);
        }
      })
    }
  }
}
</script>

<style>
.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}

label {
  display: inline-block;
  width: 100px;
  text-align: right;
}
</style>