<template>
  <div>
    <div>
      <div>
        <el-input
            style="width: 200px;margin-right: 20px"
            size="mini"
            placeholder="定位名称"
            v-model="searchPosition.name"
            clearable
            @keydown.enter.native="searchPosition.currentPage = 1;searchPosition.size = 10 ;initPosition()"
        ></el-input>
        <el-select
            v-model="searchPosition.parentId"
            size="mini"
            style="width: 200px;margin-right: 20px"
            placeholder="父级定位"
            clearable
            filterable
            @change="searchPosition.currentPage = 1;searchPosition.size = 10 ;initPosition()"
        >
          <el-option
              v-for="item in parentPosition"
              :key="item.id"
              :label="item.name"
              :value="item.id"
          >
          </el-option>
        </el-select>
        <el-button icon="el-icon-delete" type="warning" size="mini" @click="searchPosition.name = ''; searchPosition.parentId = null">
          重置
        </el-button>
        <el-button
            type="success"
            size="mini"
            icon="el-icon-search"
            @click="searchPosition.currentPage = 1;searchPosition.size = 10;initPosition()"
        >
          搜索
        </el-button>
        <el-button
            type="primary"
            size="mini"
            icon="el-icon-plus"
            @click="showAddPositionView"
            v-if="roleButton.buttonLevelComponents !== null && roleButton.buttonLevelComponents.indexOf('MrfzPosition') !== -1"
        >
          添加定位
        </el-button>
      </div>
    </div>
    <div style="margin-top: 10px">
      <el-table
          size="mini"
          :data="positionData"
          border
          stripe
          :row-class-name="tableRowClassName"
          v-loading="loading"
          element-loading-text="拼命加载中"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.8)"
      >
        <el-table-column
            prop="name"
            align="center"
            width="120"
            label="定位名称"
        >
        </el-table-column>
        <el-table-column
            prop="parent.name"
            align="center"
            width="120"
            label="父级定位"
        >
        </el-table-column>
        <el-table-column
            label="定位特性"
        >
          <template slot-scope="scope">
            <el-tooltip v-if="scope.row.characteristic && scope.row.characteristic.length > 50" class="item" effect="dark" placement="bottom">
              <span v-html="scope.row.characteristic.replace(/\n/gm, '<br>')" slot="content">{{scope.row.characteristic}}</span>
              <span v-if="scope.row.characteristic">{{scope.row.characteristic | ellipsis}}</span>
            </el-tooltip>
            <span v-else-if="scope.row.characteristic" v-html="scope.row.characteristic"></span>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column
            label="操作"
            fixed="right"
            width="100"
        >
          <template slot-scope="scope">
            <el-button v-if="roleButton.buttonLevelComponents !== null && roleButton.buttonLevelComponents.indexOf('MrfzPosition') !== -1" size="mini" style="padding: 3px" type="primary" @click="showUpdatePositionView(scope.row)">编辑</el-button>
            <el-button v-if="roleButton.buttonLevelComponents !== null && roleButton.buttonLevelComponents.indexOf('MrfzPosition') !== -1" size="mini" style="padding: 3px" type="danger" @click="deletePosition(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div style="display: flex; justify-content: flex-end; margin-top: 5px">
      <el-pagination
          background
          layout="sizes, prev, pager, next, jumper, ->, total, slot"
          :total="total"
          :current-page="searchPosition.currentPage"
          :page-size="searchPosition.size"
          @current-change="currentChange"
          @size-change="sizeChange"
      >
      </el-pagination>
    </div>

    <el-dialog
        :close-on-click-modal="false"
        :title="title"
        @close="closeDialog()"
        :visible.sync="dialogVisible"
        width="400px">
      <el-form ref="positionForm" :model="position" :rules="rules">
        <table>
          <tr>
            <el-form-item label="定位名称" prop="name">
              <el-input
                  size="mini"
                  style="width: 200px"
                  v-model="position.name"
                  placeholder="请输入定位名称"
              ></el-input>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="父级定位" prop="parentId">
              <el-select
                  v-model="position.parentId"
                  size="mini"
                  style="width: 200px;margin-right: 20px"
                  placeholder="请选择父级定位"
                  clearable
                  filterable
              >
                <el-option
                    v-for="item in parentPosition"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="定位特性">
              <el-input
                  type="textarea"
                  :rows="5"
                  size="mini"
                  style="width: 200px"
                  v-model="position.characteristic"
                  placeholder="请输入定位特性"
              ></el-input>
            </el-form-item>
          </tr>
        </table>
      </el-form>
      <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="doPosition">确 定</el-button>
        </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "MrfzPosition",
  data() {
    return {
      positionData: [],
      parentPosition: [],
      loading: false,
      total: 0,
      currentPage: 1,
      size: 10,
      searchPosition: {
        name: "",
        parentId: null,
        currentPage: 1,
        size: 10,
      },
      position: {
        id: null,
        name: "",
        parentId: null,
        characteristic: ""
      },
      dialogVisible: false,
      title: "",
      rules: {
        name: [{required: true, message: "请输入定位名称", trigger: "blur"}],
        parentId: [{required: true, message: "请选择父级定位", trigger: "change"}],
      }
    }
  },
  filters: {
    ellipsis(value) {
      if (!value) {
        return '';
      }
      if (value.length > 50) {
        return value.slice(0, 50) + '...';
      } else {
        return value;
      }
    }
  },
  computed: {
    roleButton() {
      return this.$store.state.currentRole;
    }
  },
  mounted() {
    this.initPosition();
    this.initParentPosition();
  },
  methods: {
    tableRowClassName({row, rowIndex}) {
      if (rowIndex % 2 !== 0) {
        return 'warning-row';
      } else if (rowIndex % 2 === 0) {
        return 'success-row';
      }
      return '';
    },
    initPosition() {
      this.loading = true;
      this.postRequest("/mrfz/position/getPageData", this.searchPosition).then(resp => {
        this.loading = false;
        if (resp) {
          this.total = resp.total;
          this.positionData = resp.data;
        }
      })
    },
    initParentPosition() {
      this.getRequest("/mrfz/position/getAllParentPositions").then(resp => {
        if (resp) {
          this.parentPosition = resp;
        }
      })
    },
    currentChange(page) {
      this.searchPosition.currentPage = page;
      this.initPosition();
    },
    sizeChange(page) {
      this.searchPosition.size = page;
      this.initPosition();
    },
    deletePosition(data) {
      this.$confirm(
          "此操作将永久删除[" + data.name + "]定位, 是否继续?",
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
      ).then(() => {
        this.deleteRequest("/mrfz/position/" + data.id).then(resp => {
              if (resp) {
                this.initPosition();
              }
            }
        );
      }).catch(() => {
        this.$message({
          type: "info",
          message: "已取消删除",
        });
      });
    },
    showAddPositionView(data) {
      this.position = {
        id: null,
        name: "",
        parentId: null,
        characteristic: ""
      };
      this.title = "添加定位";
      this.dialogVisible = true;
    },
    showUpdatePositionView(data) {
      // 拷贝
      this.position = Object.assign({}, data);
      this.title = "编辑定位";
      this.dialogVisible = true;
    },
    closeDialog() {
      this.$refs["positionForm"].resetFields();
    },
    doPosition() {
      if (this.position.id) {
        // 编辑
        this.$refs["positionForm"].validate((valid) => {
          if (valid) {
            this.putRequest("/mrfz/position", this.position).then(resp => {
              if (resp) {
                this.dialogVisible = false;
                this.initPosition();
                this.initParentPosition();
              }
            })
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      } else {
        // 添加
        this.$refs["positionForm"].validate((valid) => {
          if (valid) {
            this.postRequest("/mrfz/position", this.position).then(resp => {
              if (resp) {
                this.dialogVisible = false;
                this.initPosition();
                this.initParentPosition();
              }
            })
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      }
    }
  }
}
</script>

<style>
.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}

label{
  display: inline-block;
  width: 100px;
  text-align: right;
}
</style>