<template>
  <div>
    <div style="margin-top: 15px">
      <el-card>
        <div slot="header" class="clearfix">
          <span>后端服务重启</span>
        </div>
        <div>
          <el-button v-if="roleButton.buttonLevelComponents !== null && roleButton.buttonLevelComponents.indexOf('Pro') !== -1" type="warning" @click="masterXrg">xrg-platform-master</el-button>
          <el-button v-if="roleButton.buttonLevelComponents !== null && roleButton.buttonLevelComponents.indexOf('Pro') !== -1" style="margin-left: 35px" type="warning" @click="masterMentalWorld">mental-world-master</el-button>
          <el-button v-if="roleButton.buttonLevelComponents !== null && roleButton.buttonLevelComponents.indexOf('Pro') !== -1" style="margin-left: 35px" type="warning" @click="masterAdmin">xrg-admin</el-button>
        </div>
      </el-card>
    </div>
    <div style="margin-top: 15px">
      <el-card>
        <div slot="header" class="clearfix">
          <span>前端服务重启</span>
        </div>
        <div>
          <el-button v-if="roleButton.buttonLevelComponents !== null && roleButton.buttonLevelComponents.indexOf('Pro') !== -1" type="success" @click="slaveXrg">xrg</el-button>
          <el-button v-if="roleButton.buttonLevelComponents !== null && roleButton.buttonLevelComponents.indexOf('Pro') !== -1" style="margin-left: 35px" type="success" @click="slaveMentalWorld">vue-mental-world</el-button>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
export default {
  name: "Pro",
  data() {
    return {}
  },
  computed: {
    roleButton() {
      return this.$store.state.currentRole;
    }
  },
  methods: {
    async masterXrg() {
      this.$confirm(
          "此操作将会重启[后端 xrg-platform-master]服务, 是否继续?",
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
      ).then(() => {
        this.postRequest("/sys/pro/xrg-master-restart").then(resp => {
              if (resp) {
              }
            }
        );
      }).catch(() => {
        this.$message({
          type: "info",
          message: "已取消",
        });
      });
    },
    masterMentalWorld() {
      this.$confirm(
          "此操作将会重启[后端 mental-world-master]服务, 是否继续?",
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
      ).then(() => {
        this.postRequest("/sys/pro/mental-world-master-restart").then(resp => {
              if (resp) {
              }
            }
        );
      }).catch(() => {
        this.$message({
          type: "info",
          message: "已取消",
        });
      });
    },
    masterAdmin() {
      this.$confirm(
          "此操作将会重启[监控 xrg-admin]服务, 是否继续?",
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
      ).then(() => {
        this.postRequest("/sys/pro/admin-master-restart").then(resp => {
              if (resp) {
              }
            }
        );
      }).catch(() => {
        this.$message({
          type: "info",
          message: "已取消",
        });
      });
    },
    slaveXrg() {
      this.$confirm(
          "此操作将会重启[前端 xrg]服务, 是否继续?",
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
      ).then(() => {
        this.postRequest("/sys/pro/xrg-slave-restart").then(resp => {
              if (resp) {
                // 重新刷新页面
                setTimeout(() => {
                  location.reload();
                }, 500);
              }
            }
        );
      }).catch(() => {
        this.$message({
          type: "info",
          message: "已取消",
        });
      });
    },
    slaveMentalWorld() {
      this.$confirm(
          "此操作将会重启[前端 vue-mental-world]服务, 是否继续?",
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
      ).then(() => {
        this.postRequest("/sys/pro/mental-world-slave-restart").then(resp => {
              if (resp) {
              }
            }
        );
      }).catch(() => {
        this.$message({
          type: "info",
          message: "已取消",
        });
      });
    },
  }
}
</script>

<style>
.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}

label {
  display: inline-block;
  width: 100px;
  text-align: right;
}
</style>