<template>
  <div>
    <div>
      <div>
        <el-input
            style="width: 200px;margin-right: 20px"
            size="mini"
            placeholder="光锥名称"
            v-model="searchBtLightAwl.name"
            clearable
            @keydown.enter.native="searchBtLightAwl.currentPage = 1; searchBtLightAwl.size = 10; initBtLightAwl()"
        ></el-input>
        <el-select
            v-model="searchBtLightAwl.star"
            size="mini"
            style="width: 200px;margin-right: 20px"
            placeholder="光锥星级"
            clearable
            filterable
            @change="searchBtLightAwl.currentPage = 1; searchBtLightAwl.size = 10; initBtLightAwl()"
        >
          <el-option
              v-for="item in stars"
              :key="item"
              :label="item"
              :value="item"
          >
          </el-option>
        </el-select>
        <el-select
            v-model="searchBtLightAwl.destinyId"
            size="mini"
            style="width: 200px;margin-right: 20px"
            placeholder="光锥命途"
            clearable
            filterable
            @change="searchBtLightAwl.currentPage = 1; searchBtLightAwl.size = 10; initBtLightAwl()"
        >
          <el-option
              v-for="item in destinies"
              :key="item.id"
              :label="item.name"
              :value="item.id"
          >
          </el-option>
        </el-select>
        <el-button icon="el-icon-delete" type="warning" size="mini" @click="searchBtLightAwl.name = ''; searchBtLightAwl.star = null; searchBtLightAwl.destinyId = null;">
          重置
        </el-button>
        <el-button
            type="success"
            size="mini"
            icon="el-icon-search"
            @click="searchBtLightAwl.currentPage = 1; searchBtLightAwl.size = 10; initBtLightAwl()"
        >
          搜索
        </el-button>
        <el-button
            type="primary"
            size="mini"
            icon="el-icon-plus"
            @click="showAddBtLightAwlView"
            v-if="roleButton.buttonLevelComponents !== null && roleButton.buttonLevelComponents.indexOf('BtLightAwl') !== -1"
        >
          添加光锥
        </el-button>
      </div>
    </div>

    <div style="margin-top: 10px">
      <el-table
          size="mini"
          :data="btLightAwlData"
          border
          stripe
          :row-class-name="tableRowClassName"
          v-loading="loading"
          element-loading-text="拼命加载中"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.8)"
      >
        <el-table-column
            label="光锥名称"
            align="center"
            width="150"
            prop="name"
        ></el-table-column>
        <el-table-column
            label="光锥星级"
            align="center"
            sortable
            prop="star"
        >
          <template slot-scope="scope">
            <el-tag type="primary">{{scope.row.star}}</el-tag>
          </template>
        </el-table-column>
        <el-table-column
            label="光锥阶级"
            align="center"
            sortable
            prop="step"
        >
          <template slot-scope="scope">
            <el-tag type="primary">{{scope.row.step}}</el-tag>
          </template>
        </el-table-column>
        <el-table-column
            label="光锥命途"
            align="center"
        >
          <template slot-scope="scope">
            <el-tag type="danger">{{scope.row.destiny.name}}</el-tag>
          </template>
        </el-table-column>
        <el-table-column
            align="center"
            width="250"
            label="光锥技能"
        >
          <template slot-scope="scope">
            <el-tooltip v-if="scope.row.skill && toText(scope.row.skill).length > 15" class="item" effect="dark" placement="bottom">
              <span v-html="scope.row.skill.replace(/\n/gm, '<br>')" slot="content">{{scope.row.skill}}</span>
              <span v-if="scope.row.skill" v-html="$options.filters.ellipsis(scope.row.skill)"></span>
            </el-tooltip>
            <span v-else-if="scope.row.skill" v-html="toText(scope.row.skill)" slot="content"></span>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column
            label="创建时间"
            width="200"
            align="center"
            prop="createTime"
            sortable
        ></el-table-column>
        <el-table-column
            label="更新时间"
            width="200"
            align="center"
            prop="updateTime"
            sortable
        ></el-table-column>
        <el-table-column
            label="操作"
            fixed="right"
            width="100"
        >
          <template slot-scope="scope">
            <el-button v-if="roleButton.buttonLevelComponents !== null && roleButton.buttonLevelComponents.indexOf('BtLightAwl') !== -1" size="mini" style="padding: 3px" type="primary" @click="showUpdateBtLightAwlView(scope.row)">编辑</el-button>
            <el-button v-if="roleButton.buttonLevelComponents !== null && roleButton.buttonLevelComponents.indexOf('BtLightAwl') !== -1" size="mini" style="padding: 3px" type="danger" @click="deleteBtLightAwl(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div style="display: flex; justify-content: flex-end; margin-top: 5px">
      <el-pagination
          background
          layout="sizes, prev, pager, next, jumper, ->, total, slot"
          :current-page="searchBtLightAwl.currentPage"
          :page-size="searchBtLightAwl.size"
          :total="total"
          @current-change="currentChange"
          @size-change="sizeChange"
      >
      </el-pagination>
    </div>

    <el-dialog
        :close-on-click-modal="false"
        :title="title"
        @close="closeDialog()"
        :visible.sync="dialogVisible"
        width="800px">
      <el-form ref="btLightAwlForm" :model="btLightAwl" :rules="rules">
        <table>
          <tr>
            <el-form-item label="光锥名称" prop="name">
              <el-input
                  size="mini"
                  style="width: 200px"
                  v-model="btLightAwl.name"
                  placeholder="请输入光锥名称"
              ></el-input>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="光锥星级" prop="star">
              <el-select
                  v-model="btLightAwl.star"
                  size="mini"
                  style="width: 200px"
                  placeholder="光锥星级"
                  clearable
                  filterable
              >
                <el-option
                    v-for="item in stars"
                    :key="item"
                    :label="item"
                    :value="item"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="光锥阶级" prop="step">
              <el-select
                  v-model="btLightAwl.step"
                  size="mini"
                  style="width: 200px"
                  placeholder="光锥阶级"
                  clearable
                  filterable
              >
                <el-option
                    v-for="item in steps"
                    :key="item"
                    :label="item"
                    :value="item"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="光锥命途" prop="destinyId">
              <el-select
                  v-model="btLightAwl.destinyId"
                  size="mini"
                  style="width: 200px"
                  placeholder="光锥命途"
                  clearable
                  filterable
              >
                <el-option
                    v-for="item in destinies"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="光锥技能">
              <div id="richText"></div>
            </el-form-item>
          </tr>
        </table>
      </el-form>
      <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="doBtLightAwl">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
// wangeditor富文本
import E from "wangeditor"
let editor;

export default {
  name: "BtLightAwl",
  data() {
    return {
      loading: false,
      destinies: [],
      searchBtLightAwl: {
        name: '',
        star: null,
        destinyId: null,
        currentPage: 1,
        size: 10,
      },
      btLightAwl: {
        id: null,
        name: '',
        star: null,
        step: null,
        destinyId: null,
        skill: ''
      },
      total: 0,
      btLightAwlData: [],
      dialogVisible: false,
      title: '',
      rules: {
        name: [{required: true, message: "请输入光锥名称", trigger: "blur"}],
        star: [{required: true, message: "请选择光锥星级", trigger: "change"}],
        step: [{required: true, message: "请选择光锥阶级", trigger: "change"}],
        destinyId: [{required: true, message: "请选择光锥命途", trigger: "change"}],
      },
    };
  },
  filters: {
    ellipsis(value) {
      if (!value) {
        return '';
      }
      let input = value.replace(/<(style|script|iframe)[^>]*?>[\s\S]+?<\/\1\s*>/gi,'').replace(/<[^>]+?>/g,'').replace(/\s+/g,' ').replace(/ /g,' ').replace(/>/g,' ');
      if (input.length > 15) {
        return input.slice(0, 15) + '...';
      } else {
        return input;
      }
    }
  },
  computed: {
    roleButton() {
      return this.$store.state.currentRole;
    },
    stars() {
      return this.$store.state.btStars;
    },
    steps() {
      return this.$store.state.btSteps;
    }
  },
  created() {
    // 富文本 解决页面切换失效问题
    editor = ''
  },
  mounted() {
    this.initBtLightAwl();
    this.initDestinies();
  },
  methods: {
    tableRowClassName({row, rowIndex}) {
      if (rowIndex % 2 !== 0) {
        return 'warning-row';
      } else if (rowIndex % 2 === 0) {
        return 'success-row';
      }
      return '';
    },
    toText(HTML) {
      let input = HTML;
      return input.replace(/<(style|script|iframe)[^>]*?>[\s\S]+?<\/\1\s*>/gi,'').replace(/<[^>]+?>/g,'').replace(/\s+/g,' ').replace(/ /g,' ').replace(/>/g,' ');
    },
    initDestinies() {
      this.getRequest("/bt/destiny/getAll").then(resp => {
        if (resp) {
          this.destinies = resp;
        }
      })
    },
    initBtLightAwl() {
      this.loading = true;
      this.postRequest("/bt/light-awl/getPageData", this.searchBtLightAwl).then(resp => {
        this.loading = false;
        if (resp) {
          this.btLightAwlData = resp.data;
          this.total = resp.total;
        }
      })
    },
    currentChange(page) {
      this.searchBtLightAwl.currentPage = page;
      this.initBtLightAwl();
    },
    sizeChange(size) {
      this.searchBtLightAwl.size = size;
      this.initBtLightAwl();
    },
    deleteBtLightAwl(data) {
      this.$confirm(
          "此操作将永久删除[" + data.name + "]该光锥, 是否继续?",
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
      ).then(() => {
        this.deleteRequest("/bt/light-awl/" + data.id).then(resp => {
              if (resp) {
                this.initBtLightAwl();
              }
            }
        );
      }).catch(() => {
        this.$message({
          type: "info",
          message: "已取消删除",
        });
      });
    },
    showAddBtLightAwlView() {
      this.btLightAwl = {
        id: null,
        name: '',
        star: null,
        step: null,
        destinyId: null,
        skill: ''
      };
      this.dialogVisible = true;
      this.title = "添加光锥";
      // 渲染富文本
      this.$nextTick(() => {
        if (!editor) {
          editor = new E("#richText");
          editor.config.height = 300;
          // 配置上传图片地址
          editor.config.uploadImgServer = "http://" + window.server.filesUploadUrl + "/files/rich/upload";
          // 自定义fileName
          editor.config.uploadFileName = "file";
          editor.create();
        }
        // 清空内容
        editor.txt.html('')
      })
    },
    showUpdateBtLightAwlView(data) {
      // 拷贝
      this.btLightAwl = JSON.parse(JSON.stringify(data));
      this.dialogVisible = true;
      this.title = "编辑光锥";
      // 渲染富文本
      this.$nextTick(() => {
        if (!editor) {
          editor = new E("#richText");
          editor.config.height = 300;
          // 配置上传图片地址
          editor.config.uploadImgServer = "http://" + window.server.filesUploadUrl + "/files/rich/upload";
          // 自定义fileName
          editor.config.uploadFileName = "file";
          editor.create();
        }
        // 内容赋值
        editor.txt.html("");
        editor.txt.html(this.btLightAwl.skill);
      })
    },
    doBtLightAwl() {
      if (this.btLightAwl.id) {
        // 编辑
        this.$refs["btLightAwlForm"].validate((valid) => {
          if (valid) {
            // 富文本需要手动赋值
            this.btLightAwl.skill = editor.txt.html();
            this.putRequest("/bt/light-awl", this.btLightAwl).then(resp => {
              if (resp) {
                this.dialogVisible = false;
                this.initBtLightAwl();
              }
            })
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      } else {
        // 添加
        this.$refs["btLightAwlForm"].validate((valid) => {
          if (valid) {
            // 富文本需要手动赋值
            this.btLightAwl.skill = editor.txt.html();
            this.postRequest("/bt/light-awl", this.btLightAwl).then(resp => {
              if (resp) {
                this.dialogVisible = false;
                this.initBtLightAwl();
              }
            })
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      }
    },
    closeDialog() {
      this.$refs["btLightAwlForm"].resetFields();
    }
  },
};
</script>

<style>
.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}

label {
  display: inline-block;
  width: 100px;
  text-align: right;
}
</style>
