<template>
  <div>
    <div>
      <div>
        <el-input
            style="width: 200px;margin-right: 20px"
            size="mini"
            placeholder="圣遗物名称"
            v-model="searchYsholyRelic.name"
            clearable
            @keydown.enter.native="searchYsholyRelic.currentPage = 1;searchYsholyRelic.size = 10 ;initYsholyRelic()"
        ></el-input>
        <el-button icon="el-icon-delete" type="warning" size="mini" @click="searchYsholyRelic.name = '';">
          重置
        </el-button>
        <el-button
            type="success"
            size="mini"
            icon="el-icon-search"
            @click="searchYsholyRelic.currentPage = 1;searchYsholyRelic.size = 10 ;initYsholyRelic()"
        >
          搜索
        </el-button>
        <el-button
            type="primary"
            size="mini"
            icon="el-icon-plus"
            @click="showAddYsholyRelicView"
            v-if="roleButton.buttonLevelComponents !== null && roleButton.buttonLevelComponents.indexOf('YsHolyRelic') !== -1"
        >
          添加圣遗物
        </el-button>
      </div>
    </div>
    <div style="margin-top: 10px">
      <el-table
          size="mini"
          :data="ysholyRelicData"
          border
          stripe
          :row-class-name="tableRowClassName"
          v-loading="loading"
          element-loading-text="拼命加载中"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.8)"
      >
        <el-table-column
            label="圣遗物名称"
            prop="name"
        ></el-table-column>
        <el-table-column label="圣遗物两件套效果" align="center">
          <template slot-scope="scope">
            <el-tooltip v-if="scope.row.descriptionsTwo && scope.row.descriptionsTwo.length > 15" class="item" effect="dark" placement="bottom">
              <span v-html="scope.row.descriptionsTwo.replace(/\n/gm, '<br>')" slot="content">{{scope.row.descriptionsTwo}}</span>
              <span v-if="scope.row.descriptionsTwo">{{scope.row.descriptionsTwo | ellipsis}}</span>
            </el-tooltip>
            <span v-else-if="scope.row.descriptionsTwo" v-html="scope.row.descriptionsTwo"></span>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column label="圣遗物四件套效果/二加二另一套效果" align="center">
          <template slot-scope="scope">
            <el-tooltip v-if="scope.row.descriptionsFour && scope.row.descriptionsFour.length > 15" class="item" effect="dark" placement="bottom">
              <span v-html="scope.row.descriptionsFour.replace(/\n/gm, '<br>')" slot="content">{{scope.row.descriptionsFour}}</span>
              <span v-if="scope.row.descriptionsFour">{{scope.row.descriptionsFour | ellipsis}}</span>
            </el-tooltip>
            <span v-else-if="scope.row.descriptionsFour" v-html="scope.row.descriptionsFour"></span>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column
            label="操作"
            fixed="right"
            width="100"
        >
          <template slot-scope="scope">
            <el-button v-if="roleButton.buttonLevelComponents !== null && roleButton.buttonLevelComponents.indexOf('YsHolyRelic') !== -1" size="mini" style="padding: 3px" type="primary" @click="showUpdateYsholyRelicView(scope.row)">编辑</el-button>
            <el-button v-if="roleButton.buttonLevelComponents !== null && roleButton.buttonLevelComponents.indexOf('YsHolyRelic') !== -1" size="mini" style="padding: 3px" type="danger" @click="deleteYsholyRelic(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div style="display: flex; justify-content: flex-end; margin-top: 5px">
      <el-pagination
          background
          layout="sizes, prev, pager, next, jumper, ->, total, slot"
          :total="total"
          :current-page="searchYsholyRelic.currentPage"
          :page-size="searchYsholyRelic.size"
          @current-change="currentChange"
          @size-change="sizeChange"
      >
      </el-pagination>
    </div>

    <el-dialog
        :close-on-click-modal="false"
        :title="title"
        :visible.sync="dialogVisible"
        width="400px">
      <el-form ref="ysholyRelicForm" :model="ysholyRelic" :rules="rules">
        <table>
          <tr>
            <el-form-item label="圣遗物名称" prop="name">
              <el-input
                  size="mini"
                  style="width: 200px"
                  v-model="ysholyRelic.name"
                  placeholder="请输入圣遗物名称"
              ></el-input>
            </el-form-item>
            <el-form-item label="两件套效果">
              <el-input
                  type="textarea"
                  :rows="5"
                  size="mini"
                  style="width: 200px"
                  v-model="ysholyRelic.descriptionsTwo"
                  placeholder="请输入圣遗物两件套效果"
              ></el-input>
            </el-form-item>
            <el-form-item label="四件套效果">
              <el-input
                  type="textarea"
                  :rows="5"
                  size="mini"
                  style="width: 200px"
                  v-model="ysholyRelic.descriptionsFour"
                  placeholder="请输入四件套效果/二加二另一套效果"
              ></el-input>
            </el-form-item>
          </tr>
        </table>
      </el-form>
      <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="doYsholyRelic">确 定</el-button>
        </span>
    </el-dialog>

  </div>
</template>

<script>
export default {
  name: "YsHolyRelic",
  data() {
    return {
      ysholyRelicData: [],
      loading: false,
      searchYsholyRelic: {
        name: '',
        currentPage: 1,
        size: 10,
      },
      ysholyRelic: {
        id: null,
        name: '',
        descriptionsTwo: '',
        descriptionsFour: ''
      },
      total: 0,
      dialogVisible: false,
      title: '',
      rules: {
        name: [{required: true, message: "请输入圣遗物名称", trigger: "blur"}],
      }
    }
  },
  filters: {
    ellipsis(value) {
      if (!value) {
        return '';
      }
      if (value.length > 15) {
        return value.slice(0, 15) + '...';
      } else {
        return value;
      }
    }
  },
  computed: {
    roleButton() {
      return this.$store.state.currentRole;
    }
  },
  mounted() {
    this.initYsholyRelic();
  },
  methods: {
    tableRowClassName({row, rowIndex}) {
      if (rowIndex % 2 !== 0) {
        return 'warning-row';
      } else if (rowIndex % 2 === 0) {
        return 'success-row';
      }
      return '';
    },
    initYsholyRelic() {
      this.loading = true;
      this.postRequest("/ys/holy-relic/getPageData", this.searchYsholyRelic).then(resp => {
        this.loading = false;
        if (resp) {
          this.ysholyRelicData = resp.data;
          this.total = resp.total;
        }
      })
    },
    currentChange(page) {
      this.searchYsholyRelic.currentPage = page;
      this.initYsholyRelic();
    },
    sizeChange(size) {
      this.searchYsholyRelic.size = size;
      this.initYsholyRelic();
    },
    deleteYsholyRelic(data) {
      this.$confirm(
          "此操作将永久删除[" + data.name + "]圣遗物, 是否继续?",
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
      ).then(() => {
        this.deleteRequest("/ys/holy-relic/" + data.id).then(resp => {
              if (resp) {
                this.initYsholyRelic();
              }
            }
        );
      }).catch(() => {
        this.$message({
          type: "info",
          message: "已取消删除",
        });
      });
    },
    showAddYsholyRelicView() {
      this.ysholyRelic = {
        id: null,
        name: '',
        descriptionsTwo: '',
        descriptionsFour: ''
      };
      this.dialogVisible = true;
      this.title = "添加圣遗物";
    },
    showUpdateYsholyRelicView(data) {
      // 拷贝
      this.ysholyRelic = Object.assign({}, data);
      this.dialogVisible = true;
      this.title = "编辑圣遗物";
    },
    doYsholyRelic() {
      if (this.ysholyRelic.id) {
        // 编辑
        this.$refs["ysholyRelicForm"].validate((valid) => {
          if (valid) {
            this.putRequest("/ys/holy-relic", this.ysholyRelic).then(resp => {
              if (resp) {
                this.dialogVisible = false;
                this.initYsholyRelic();
              }
            })
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      } else {
        // 添加
        this.$refs["ysholyRelicForm"].validate((valid) => {
          if (valid) {
            this.postRequest("/ys/holy-relic", this.ysholyRelic).then(resp => {
              if (resp) {
                this.dialogVisible = false;
                this.initYsholyRelic();
              }
            })
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      }
    }
  }
}
</script>

<style>
.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}

label{
  display: inline-block;
  width: 100px;
  text-align: right;
}
</style>
