<template>
  <div>
    <div>
      <div>
        <el-input
            style="width: 200px;margin-right: 20px"
            size="mini"
            placeholder="角色名称"
            v-model="searchBtRole.name"
            clearable
            @keydown.enter.native="searchBtRole.currentPage = 1; searchBtRole.size = 10; initBtRole()"
        ></el-input>
        <el-select
            v-model="searchBtRole.star"
            size="mini"
            style="width: 200px;margin-right: 20px"
            placeholder="角色星级"
            clearable
            filterable
            @change="searchBtRole.currentPage = 1; searchBtRole.size = 10; initBtRole()"
        >
          <el-option
              v-for="item in stars"
              :key="item"
              :label="item"
              :value="item"
          >
          </el-option>
        </el-select>
        <el-select
            v-model="searchBtRole.attributes"
            size="mini"
            style="width: 200px;margin-right: 20px"
            placeholder="角色属性"
            clearable
            filterable
            @change="searchBtRole.currentPage = 1; searchBtRole.size = 10; initBtRole()"
        >
          <el-option
              v-for="item in btAttributes"
              :key="item"
              :label="item"
              :value="item"
          >
          </el-option>
        </el-select>
        <el-select
            v-model="searchBtRole.destinyId"
            size="mini"
            style="width: 200px;margin-right: 20px"
            placeholder="角色命途"
            clearable
            filterable
            @change="searchBtRole.currentPage = 1; searchBtRole.size = 10; initBtRole()"
        >
          <el-option
              v-for="item in destinies"
              :key="item.id"
              :label="item.name"
              :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <div style="margin-top: 10px">
        <el-select
            v-model="searchBtRole.lightAwlId"
            size="mini"
            style="width: 200px;margin-right: 20px"
            placeholder="角色光锥"
            clearable
            filterable
            @change="searchBtRole.currentPage = 1; searchBtRole.size = 10; initBtRole()"
        >
          <el-option
              v-for="item in lightAwls"
              :key="item.id"
              :label="item.name"
              :value="item.id"
          >
          </el-option>
        </el-select>
        <el-button icon="el-icon-delete" type="warning" size="mini" @click="resetBtRole">
          重置
        </el-button>
        <el-button
            type="success"
            size="mini"
            icon="el-icon-search"
            @click="searchBtRole.currentPage = 1; searchBtRole.size = 10; initBtRole()"
        >
          搜索
        </el-button>
        <el-button
            type="primary"
            size="mini"
            icon="el-icon-plus"
            @click="showAddBtRoleView"
            v-if="roleButton.buttonLevelComponents !== null && roleButton.buttonLevelComponents.indexOf('BtRole') !== -1"
        >
          添加角色
        </el-button>
      </div>
    </div>

    <div style="margin-top: 10px">
      <el-table
          size="mini"
          :data="btRoleData"
          border
          stripe
          :row-class-name="tableRowClassName"
          v-loading="loading"
          element-loading-text="拼命加载中"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.8)"
      >
        <el-table-column
            label="角色名称"
            align="center"
            width="100"
            prop="name"
        ></el-table-column>
        <el-table-column
            label="角色星级"
            align="center"
            sortable
            prop="star"
        >
          <template slot-scope="scope">
            <el-tag type="primary">{{scope.row.star}}</el-tag>
          </template>
        </el-table-column>
        <el-table-column
            label="角色属性"
            align="center"
        >
          <template slot-scope="scope">
            <el-tag type="success">{{scope.row.attributes}}</el-tag>
          </template>
        </el-table-column>
        <el-table-column
            label="角色命途"
            align="center"
        >
          <template slot-scope="scope">
            <el-tag type="danger">{{scope.row.destiny.name}}</el-tag>
          </template>
        </el-table-column>
        <el-table-column
            label="角色光锥"
            width="150"
            align="center"
        >
          <template slot-scope="scope">
            <el-tag type="primary">{{scope.row.lightAwl.name}}</el-tag>
          </template>
        </el-table-column>
        <el-table-column
            label="角色遗器外"
            width="150"
            align="center"
        >
          <template slot-scope="scope">
            <el-tag v-if="scope.row.relicOut" type="info">{{scope.row.relicOut.name}}</el-tag>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column
            label="角色遗器内"
            width="150"
            align="center"
        >
          <template slot-scope="scope">
            <el-tag v-if="scope.row.relicIn" type="info">{{scope.row.relicIn.name}}</el-tag>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column
            align="center"
            width="250"
            label="角色描述"
        >
          <template slot-scope="scope">
            <el-tooltip v-if="scope.row.remark && toText(scope.row.remark).length > 15" class="item" effect="dark" placement="bottom">
              <span v-html="scope.row.remark.replace(/\n/gm, '<br>')" slot="content">{{scope.row.remark}}</span>
              <span v-if="scope.row.remark" v-html="$options.filters.ellipsis(scope.row.remark)"></span>
            </el-tooltip>
            <span v-else-if="scope.row.remark" v-html="toText(scope.row.remark)" slot="content"></span>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column
            label="操作"
            fixed="right"
            width="100"
        >
          <template slot-scope="scope">
            <el-button v-if="roleButton.buttonLevelComponents !== null && roleButton.buttonLevelComponents.indexOf('BtRole') !== -1" size="mini" style="padding: 3px" type="primary" @click="showUpdateBtRoleView(scope.row)">编辑</el-button>
            <el-button v-if="roleButton.buttonLevelComponents !== null && roleButton.buttonLevelComponents.indexOf('BtRole') !== -1" size="mini" style="padding: 3px" type="danger" @click="deleteBtRole(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div style="display: flex; justify-content: flex-end; margin-top: 5px">
      <el-pagination
          background
          layout="sizes, prev, pager, next, jumper, ->, total, slot"
          :current-page="searchBtRole.currentPage"
          :page-size="searchBtRole.size"
          :total="total"
          @current-change="currentChange"
          @size-change="sizeChange"
      >
      </el-pagination>
    </div>

    <el-dialog
        :close-on-click-modal="false"
        :title="title"
        @close="closeDialog()"
        :visible.sync="dialogVisible"
        width="800px">
      <el-form ref="btRoleForm" :model="btRole" :rules="rules">
        <table>
          <tr>
            <el-form-item label="角色名称" prop="name">
              <el-input
                  size="mini"
                  style="width: 200px"
                  v-model="btRole.name"
                  placeholder="请输入角色名称"
              ></el-input>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="角色星级" prop="star">
              <el-select
                  v-model="btRole.star"
                  size="mini"
                  style="width: 200px"
                  placeholder="角色星级"
                  clearable
                  filterable
              >
                <el-option
                    v-for="item in stars"
                    :key="item"
                    :label="item"
                    :value="item"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="角色属性" prop="attributes">
              <el-select
                  v-model="btRole.attributes"
                  size="mini"
                  style="width: 200px"
                  placeholder="角色属性"
                  clearable
                  filterable
              >
                <el-option
                    v-for="item in btAttributes"
                    :key="item"
                    :label="item"
                    :value="item"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="角色命途" prop="destinyId">
              <el-select
                  v-model="btRole.destinyId"
                  size="mini"
                  style="width: 200px"
                  placeholder="角色命途"
                  clearable
                  filterable
              >
                <el-option
                    v-for="item in destinies"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="角色光锥" prop="lightAwlId">
              <el-select
                  v-model="btRole.lightAwlId"
                  size="mini"
                  style="width: 200px"
                  placeholder="角色光锥"
                  clearable
                  filterable
              >
                <el-option
                    v-for="item in lightAwls"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="角色遗器外">
              <el-select
                  v-model="btRole.relicOutId"
                  size="mini"
                  style="width: 200px"
                  placeholder="角色遗器外"
                  clearable
                  filterable
              >
                <el-option
                    v-for="item in outRelics"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="角色遗器内">
              <el-select
                  v-model="btRole.relicInId"
                  size="mini"
                  style="width: 200px"
                  placeholder="角色遗器内"
                  clearable
                  filterable
              >
                <el-option
                    v-for="item in inRelics"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="角色描述">
              <div id="richText"></div>
            </el-form-item>
          </tr>
        </table>
      </el-form>
      <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="doBtRole">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
// wangeditor富文本
import E from "wangeditor"
let editor;

export default {
  name: "BtRole",
  data() {
    return {
      loading: false,
      destinies: [],
      lightAwls: [],
      inRelics: [],
      outRelics: [],
      searchBtRole: {
        name: '',
        star: null,
        attributes: '',
        destinyId: null,
        lightAwlId: null,
        currentPage: 1,
        size: 10,
      },
      btRole: {
        id: null,
        name: '',
        star: null,
        attributes: '',
        destinyId: null,
        lightAwlId: null,
        relicOutId: null,
        relicInId: null,
        remark: ''
      },
      total: 0,
      btRoleData: [],
      dialogVisible: false,
      title: '',
      rules: {
        name: [{required: true, message: "请输入角色名称", trigger: "blur"}],
        star: [{required: true, message: "请选择角色星级", trigger: "change"}],
        attributes: [{required: true, message: "请选择角色属性", trigger: "change"}],
        destinyId: [{required: true, message: "请选择角色命途", trigger: "change"}],
        lightAwlId: [{required: true, message: "请选择角色光锥", trigger: "change"}],
      },
    };
  },
  filters: {
    ellipsis(value) {
      if (!value) {
        return '';
      }
      let input = value.replace(/<(style|script|iframe)[^>]*?>[\s\S]+?<\/\1\s*>/gi,'').replace(/<[^>]+?>/g,'').replace(/\s+/g,' ').replace(/ /g,' ').replace(/>/g,' ');
      if (input.length > 15) {
        return input.slice(0, 15) + '...';
      } else {
        return input;
      }
    }
  },
  computed: {
    roleButton() {
      return this.$store.state.currentRole;
    },
    stars() {
      return this.$store.state.btStars;
    },
    btAttributes() {
      return this.$store.state.btAttributes;
    }
  },
  created() {
    // 富文本 解决页面切换失效问题
    editor = ''
  },
  mounted() {
    this.initBtRole();
    this.initDestinies();
    this.initLightAwls();
    this.initInRelics();
    this.initOutRelics();
  },
  methods: {
    tableRowClassName({row, rowIndex}) {
      if (rowIndex % 2 !== 0) {
        return 'warning-row';
      } else if (rowIndex % 2 === 0) {
        return 'success-row';
      }
      return '';
    },
    toText(HTML) {
      let input = HTML;
      return input.replace(/<(style|script|iframe)[^>]*?>[\s\S]+?<\/\1\s*>/gi,'').replace(/<[^>]+?>/g,'').replace(/\s+/g,' ').replace(/ /g,' ').replace(/>/g,' ');
    },
    initDestinies() {
      this.getRequest("/bt/destiny/getAll").then(resp => {
        if (resp) {
          this.destinies = resp;
        }
      })
    },
    initLightAwls() {
      this.getRequest("/bt/light-awl/getAll").then(resp => {
        if (resp) {
          this.lightAwls = resp;
        }
      })
    },
    initInRelics() {
      let params = {
        type: 2
      };
      this.postRequest("/bt/relic/getAll", params).then(resp => {
        if (resp) {
          this.inRelics = resp;
        }
      })
    },
    initOutRelics() {
      let params = {
        type: 1
      };
      this.postRequest("/bt/relic/getAll", params).then(resp => {
        if (resp) {
          this.outRelics = resp;
        }
      })
    },
    initBtRole() {
      this.loading = true;
      this.postRequest("/bt/role/getPageData", this.searchBtRole).then(resp => {
        this.loading = false;
        if (resp) {
          this.btRoleData = resp.data;
          this.total = resp.total;
        }
      })
    },
    currentChange(page) {
      this.searchBtRole.currentPage = page;
      this.initBtRole();
    },
    sizeChange(size) {
      this.searchBtRole.size = size;
      this.initBtRole();
    },
    deleteBtRole(data) {
      this.$confirm(
          "此操作将永久删除[" + data.name + "]该角色, 是否继续?",
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
      ).then(() => {
        this.deleteRequest("/bt/role/" + data.id).then(resp => {
              if (resp) {
                this.initBtRole();
              }
            }
        );
      }).catch(() => {
        this.$message({
          type: "info",
          message: "已取消删除",
        });
      });
    },
    showAddBtRoleView() {
      this.btRole = {
        id: null,
        name: '',
        star: null,
        attributes: '',
        destinyId: null,
        lightAwlId: null,
        relicOutId: null,
        relicInId: null,
        remark: ''
      };
      this.dialogVisible = true;
      this.title = "添加角色";
      // 渲染富文本
      this.$nextTick(() => {
        if (!editor) {
          editor = new E("#richText");
          editor.config.height = 300;
          // 配置上传图片地址
          editor.config.uploadImgServer = "http://" + window.server.filesUploadUrl + "/files/rich/upload";
          // 自定义fileName
          editor.config.uploadFileName = "file";
          editor.create();
        }
        // 清空内容
        editor.txt.html('')
      })
    },
    showUpdateBtRoleView(data) {
      // 拷贝
      this.btRole = JSON.parse(JSON.stringify(data));
      this.dialogVisible = true;
      this.title = "编辑角色";
      // 渲染富文本
      this.$nextTick(() => {
        if (!editor) {
          editor = new E("#richText");
          editor.config.height = 300;
          // 配置上传图片地址
          editor.config.uploadImgServer = "http://" + window.server.filesUploadUrl + "/files/rich/upload";
          // 自定义fileName
          editor.config.uploadFileName = "file";
          editor.create();
        }
        // 内容赋值
        editor.txt.html("");
        editor.txt.html(this.btRole.remark);
      })
    },
    doBtRole() {
      if (this.btRole.id) {
        // 编辑
        this.$refs["btRoleForm"].validate((valid) => {
          if (valid) {
            // 富文本需要手动赋值
            this.btRole.remark = editor.txt.html();
            this.putRequest("/bt/role", this.btRole).then(resp => {
              if (resp) {
                this.dialogVisible = false;
                this.initBtRole();
              }
            })
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      } else {
        // 添加
        this.$refs["btRoleForm"].validate((valid) => {
          if (valid) {
            // 富文本需要手动赋值
            this.btRole.remark = editor.txt.html();
            this.postRequest("/bt/role", this.btRole).then(resp => {
              if (resp) {
                this.dialogVisible = false;
                this.initBtRole();
              }
            })
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      }
    },
    closeDialog() {
      this.$refs["btRoleForm"].resetFields();
    },
    resetBtRole() {
      this.searchBtRole.name = "";
      this.searchBtRole.star = null;
      this.searchBtRole.attributes = '';
      this.searchBtRole.destinyId = null;
      this.searchBtRole.lightAwlId = null;
    }
  },
};
</script>

<style>
.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}

label {
  display: inline-block;
  width: 100px;
  text-align: right;
}
</style>
