<template>
  <div>
    <div>
      <div>
        <el-input
            style="width: 200px;margin-right: 20px"
            size="mini"
            placeholder="材料名称"
            v-model="searchMaterials.name"
            clearable
            @keydown.enter.native="searchMaterials.currentPage = 1;searchMaterials.size = 10 ;initMaterials()"
        ></el-input>
        <el-select
            v-model="searchMaterials.type"
            size="mini"
            style="width: 200px;margin-right: 20px"
            placeholder="材料类型"
            clearable
            filterable
            @change="searchMaterials.currentPage = 1;searchMaterials.size = 10 ;initMaterials()"
        >
          <el-option
              v-for="item in materialsType"
              :key="item.code"
              :label="item.desc"
              :value="item.code"
          >
          </el-option>
        </el-select>
        <el-button icon="el-icon-delete" type="warning" size="mini" @click="searchMaterials.name = ''">
          重置
        </el-button>
        <el-button
            type="success"
            size="mini"
            icon="el-icon-search"
            @click="searchMaterials.currentPage = 1;searchMaterials.size = 10;initMaterials()"
        >
          搜索
        </el-button>
        <el-button
            type="primary"
            size="mini"
            icon="el-icon-plus"
            @click="showAddMaterialsView"
            v-if="roleButton.buttonLevelComponents !== null && roleButton.buttonLevelComponents.indexOf('MrfzMaterials') !== -1"
        >
          添加材料
        </el-button>
      </div>
    </div>
    <div style="margin-top: 10px">
      <el-table
          size="mini"
          :data="materialsData"
          border
          stripe
          :row-class-name="tableRowClassName"
          v-loading="loading"
          element-loading-text="拼命加载中"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.8)"
      >
        <el-table-column
            prop="name"
            align="center"
            label="材料名称"
        >
        </el-table-column>
        <el-table-column label="材料类型" align="center" width="250">
          <template slot-scope="scope">
            <el-tag type="primary">{{scope.row.typeName}}</el-tag>
          </template>
        </el-table-column>
        <el-table-column
          prop="round"
          align="center"
          width="150"
          label="材料推荐关卡"
        >
        </el-table-column>
        <el-table-column
            prop="ps"
            sortable
            align="center"
            width="200"
            label="消耗体力值"
        >
        </el-table-column>
        <el-table-column
            prop="probability"
            align="center"
            width="200"
            label="掉落概率"
        >
        </el-table-column>
        <el-table-column
            label="操作"
            fixed="right"
            width="100"
        >
          <template slot-scope="scope">
            <el-button v-if="roleButton.buttonLevelComponents !== null && roleButton.buttonLevelComponents.indexOf('MrfzMaterials') !== -1" size="mini" style="padding: 3px;" type="primary" @click="showUpdateMaterialsView(scope.row)">编辑</el-button>
            <el-button v-if="roleButton.buttonLevelComponents !== null && roleButton.buttonLevelComponents.indexOf('MrfzMaterials') !== -1" size="mini" style="padding: 3px;" type="danger" @click="deleteMaterials(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div style="display: flex; justify-content: flex-end; margin-top: 5px">
      <el-pagination
          background
          layout="sizes, prev, pager, next, jumper, ->, total, slot"
          :total="total"
          :current-page="searchMaterials.currentPage"
          :page-size="searchMaterials.size"
          @current-change="currentChange"
          @size-change="sizeChange"
      >
      </el-pagination>
    </div>

    <el-dialog
        :close-on-click-modal="false"
        :title="title"
        @close="closeDialog()"
        :visible.sync="dialogVisible"
        width="425px">
      <el-form ref="materialsForm" :model="materials" :rules="rules" label-width="125px">
        <table>
          <tr>
            <el-form-item label="材料名称" prop="name">
              <el-input
                  size="mini"
                  style="width: 200px"
                  v-model="materials.name"
                  placeholder="请输入材料名称"
              ></el-input>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="材料类型" prop="type">
              <el-select
                  v-model="materials.type"
                  size="mini"
                  style="width: 200px;margin-right: 20px"
                  placeholder="请选择材料类型"
                  clearable
                  filterable
              >
                <el-option
                    v-for="item in materialsType"
                    :key="item.code"
                    :label="item.desc"
                    :value="item.code"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="材料推荐关卡" prop="round">
              <el-input
                  size="mini"
                  style="width: 200px"
                  v-model="materials.round"
                  placeholder="请输入材料推荐关卡"
              ></el-input>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="消耗体力值" prop="ps">
              <el-input
                  size="mini"
                  style="width: 200px"
                  v-model="materials.ps"
                  placeholder="请输入消耗体力值"
              ></el-input>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="掉落概率" prop="probability">
              <el-select
                  v-model="materials.probability"
                  size="mini"
                  style="width: 200px;margin-right: 20px"
                  placeholder="请选择掉落概率"
                  clearable
                  filterable
              >
                <el-option
                    v-for="item in probabilities"
                    :key="item"
                    :label="item"
                    :value="item"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </tr>
        </table>
      </el-form>
      <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="doMaterials">确 定</el-button>
        </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "MrfzMaterials",
  data() {
    return {
      materialsData: [],
      materialsType: [],
      loading: false,
      total: 0,
      currentPage: 1,
      size: 10,
      searchMaterials: {
        name: "",
        type: null,
        currentPage: 1,
        size: 10,
      },
      materials: {
        id: null,
        name: "",
        type: null,
        round: "",
        ps: "",
        probability: ""
      },
      dialogVisible: false,
      title: "",
      rules: {
        name: [{required: true, message: "请输入材料名称", trigger: "blur"}],
        type: [{required: true, message: "请选择材料类型", trigger: "change"}],
        round: [{required: true, message: "请输入材料推荐关卡", trigger: "blur"}],
        ps: [{required: true, message: "请输入消耗体力值", trigger: "blur"}],
        probability: [{required: true, message: "请输入掉落概率", trigger: "blur"}]
      }
    }
  },
  computed: {
    probabilities() {
      return this.$store.state.mrfzMaterialsProbabilities;
    },
    roleButton() {
      return this.$store.state.currentRole;
    }
  },
  mounted() {
    this.initMaterials();
    this.initMaterialsType();
  },
  methods: {
    tableRowClassName({row, rowIndex}) {
      if (rowIndex % 2 !== 0) {
        return 'warning-row';
      } else if (rowIndex % 2 === 0) {
        return 'success-row';
      }
      return '';
    },
    initMaterials() {
      this.loading = true;
      this.postRequest("/mrfz/materials/getPageData", this.searchMaterials).then(resp => {
        this.loading = false;
        if (resp) {
          this.total = resp.total;
          this.materialsData = resp.data;
        }
      })
    },
    initMaterialsType() {
      this.getRequest("/mrfz/materials/materialsType").then(resp => {
        if (resp) {
          this.materialsType = resp;
        }
      })
    },
    currentChange(page) {
      this.searchMaterials.currentPage = page;
      this.initMaterials();
    },
    sizeChange(page) {
      this.searchMaterials.size = page;
      this.initMaterials();
    },
    deleteMaterials(data) {
      this.$confirm(
          "此操作将永久删除[" + data.name + "]材料, 是否继续?",
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
      ).then(() => {
        this.deleteRequest("/mrfz/materials/" + data.id).then(resp => {
              if (resp) {
                this.initMaterials();
              }
            }
        );
      }).catch(() => {
        this.$message({
          type: "info",
          message: "已取消删除",
        });
      });
    },
    showAddMaterialsView(data) {
      this.materials = {
        id: null,
        name: "",
        type: null,
        round: "",
        ps: "",
        probability: ""
      };
      this.title = "添加材料";
      this.dialogVisible = true;
    },
    showUpdateMaterialsView(data) {
      // 拷贝
      this.materials = Object.assign({}, data);
      this.title = "编辑材料";
      this.dialogVisible = true;
    },
    closeDialog() {
      this.$refs["materialsForm"].resetFields();
    },
    doMaterials() {
      if (this.materials.id) {
        // 编辑
        this.$refs["materialsForm"].validate((valid) => {
          if (valid) {
            this.putRequest("/mrfz/materials", this.materials).then(resp => {
              if (resp) {
                this.dialogVisible = false;
                this.initMaterials();
              }
            })
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      } else {
        // 添加
        this.$refs["materialsForm"].validate((valid) => {
          if (valid) {
            this.postRequest("/mrfz/materials", this.materials).then(resp => {
              if (resp) {
                this.dialogVisible = false;
                this.initMaterials();
              }
            })
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      }
    }
  }
}
</script>

<style>
.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}

label{
  display: inline-block;
  width: 100px;
  text-align: right;
}
</style>