<template>
  <div>

    <div style="margin-top: 15px">
      <el-select
          v-model="param.to"
          size="mini"
          style="width: 250px;"
          placeholder="翻译语言类型"
          clearable
          filterable
      >
        <el-option
            v-for="item in languages"
            :key="item.code"
            :label="item.name"
            :value="item.code"
        >
        </el-option>
      </el-select>
      <el-tag style="margin-left: 20px" type="primary">{{ tip }}</el-tag>
    </div>

    <el-input
        style="margin-top: 15px"
        size="mini"
        v-model="param.q"
        placeholder="请输入翻译文本"
        clearable
        @keydown.enter.native="getTextTranslation()"
    >
    </el-input>

    <div style="margin-top: 15px">
      <el-button icon="el-icon-delete" type="warning" size="mini" @click="param.q = '';">
        重置
      </el-button>
      <el-button
          type="success"
          size="mini"
          icon="el-icon-search"
          @click="getTextTranslation()"
      >
        翻译
      </el-button>
    </div>

    <el-card class="box-card" v-if="res.translation !== undefined && res.translation !== null && res.translation !== ''">
      {{ res.translation }}
    </el-card>

    <div style="margin-top: 20px; font-size: 20px; font-weight: bold; color: #db6fd2">历史记录</div>

    <div style="margin-top: 15px">
      <el-select
          v-model="searchRecord.roleId"
          size="mini"
          style="width: 200px;margin-right: 20px"
          placeholder="角色"
          clearable
          filterable
          @change="searchRecord.currentPage = 1; searchRecord.size = 5; getRecordList()"
      >
        <el-option
            v-for="item in roles"
            :key="item.id"
            :label="item.realname"
            :value="item.id"
        >
        </el-option>
      </el-select>
      <el-date-picker
          style="margin-right: 20px"
          size="mini"
          v-model="searchRecord.time"
          type="datetimerange"
          value-format="yyyy-MM-dd HH:mm:ss"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          @change="searchRecord.currentPage = 1; searchRecord.size = 5; getRecordList()"
      >
      </el-date-picker>
      <el-button icon="el-icon-delete" type="warning" size="mini" @click="searchRecord.startTime == null; searchRecord.endTime == null; searchRecord.time = []">
        重置
      </el-button>
      <el-button
          type="success"
          size="mini"
          icon="el-icon-search"
          @click="searchRecord.currentPage = 1; searchRecord.size = 5; getRecordList()"
      >
        搜索
      </el-button>
    </div>

    <div style="margin-top: 10px">
      <el-table
          size="mini"
          :data="recordData"
          border
          stripe
          :row-class-name="tableRowClassName"
          v-loading="loading"
          element-loading-text="拼命加载中"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.8)"
      >
        <el-table-column label="待翻译文本">
          <template slot-scope="scope">
            <el-tooltip v-if="scope.row.query && toText(scope.row.query).length > 15" class="item" effect="dark" placement="right-end">
              <span v-html="scope.row.query.replace(/\n/gm, '<br>')" slot="content">{{scope.row.query}}</span>
              <span v-if="scope.row.query" v-html="$options.filters.ellipsis(scope.row.query)"></span>
            </el-tooltip>
            <span v-else-if="scope.row.query" v-html="scope.row.query"></span>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column
            label="翻译语言类型"
            align="center"
            width="250"
            prop="languageName"
        ></el-table-column>
        <el-table-column label="翻译文本">
          <template slot-scope="scope">
            <el-tooltip v-if="scope.row.translation && toText(scope.row.translation).length > 15" class="item" effect="dark" placement="right-end">
              <span v-html="scope.row.translation.replace(/\n/gm, '<br>')" slot="content">{{scope.row.translation}}</span>
              <span v-if="scope.row.translation" v-html="$options.filters.ellipsis(scope.row.translation)"></span>
            </el-tooltip>
            <span v-else-if="scope.row.translation" v-html="scope.row.translation"></span>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column
            label="翻译时间"
            align="center"
            sortable
            width="250"
            prop="createTime"
        ></el-table-column>
        <el-table-column
            label="操作"
            width="100"
            fixed="right"
        >
          <template slot-scope="scope">
            <el-button size="mini" style="padding: 3px" type="warning" @click="showRecord(scope.row)">查看</el-button>
            <el-button size="mini" style="padding: 3px" type="danger" @click="deleteRecord(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div style="display: flex; justify-content: flex-end; margin-top: 5px">
      <el-pagination
          background
          layout="sizes, prev, pager, next, jumper, ->, total, slot"
          :page-sizes="[5, 10, 15]"
          :total="total"
          :current-page="searchRecord.currentPage"
          :page-size="searchRecord.size"
          @current-change="currentChange"
          @size-change="sizeChange"
      >
      </el-pagination>
    </div>

    <el-dialog
        :close-on-click-modal="false"
        title="查看记录"
        :visible.sync="dialogVisible"
        width="700px"
    >
      <el-card>
        <h1>翻译前</h1>
        <el-input style="margin-top: 5px; font-size: 16px" type="textarea" :rows="10" size="mini" v-model="showData.query"></el-input>

        <h1 style="margin-top: 50px">翻译后</h1>
        <el-input style="margin-top: 5px; font-size: 16px" type="textarea" :rows="10" size="mini" v-model="showData.translation"></el-input>
      </el-card>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "Translate",
  data() {
    return {
      loading: false,
      recordData: [],
      total: 0,
      searchRecord: {
        time: [],
        startTime: null,
        endTime: null,
        currentPage: 1,
        size: 5,
      },
      roles: [],
      dialogVisible: false,
      showData: {},
      param: {
        q: "",
        to: ""
      },
      tip: "如果不选择默认自动识别",
      languages: [],
      res: {
        query: "",
        translation: "",
      }
    }
  },
  filters: {
    ellipsis(value) {
      if (!value) {
        return '';
      }
      let input = value.replace(/<(style|script|iframe)[^>]*?>[\s\S]+?<\/\1\s*>/gi,'').replace(/<[^>]+?>/g,'').replace(/\s+/g,' ').replace(/ /g,' ').replace(/>/g,' ');
      if (input.length > 15) {
        return input.slice(0, 15) + '...';
      } else {
        return value;
      }
    }
  },
  mounted() {
    this.getLanguages();
    this.initRoles();
    this.getRecordList();
  },
  methods: {
    tableRowClassName({row, rowIndex}) {
      if (rowIndex % 2 !== 0) {
        return 'warning-row';
      } else if (rowIndex % 2 === 0) {
        return 'success-row';
      }
      return '';
    },
    toText(HTML) {
      let input = HTML;
      return input.replace(/<(style|script|iframe)[^>]*?>[\s\S]+?<\/\1\s*>/gi,'').replace(/<[^>]+?>/g,'').replace(/\s+/g,' ').replace(/ /g,' ').replace(/>/g,' ');
    },
    getLanguages() {
      this.getRequest("/work/translate/getLanguages").then(resp => {
        if (resp) {
          this.languages = resp;
        }
      })
    },
    getTextTranslation() {
      if (this.param.q === undefined || this.param.q === null || this.param.q === '') {
        this.$message.info("请输入需要翻译的文本");
        return;
      }
      this.postRequest("/work/translate/text", this.param).then(resp => {
        if (resp) {
          this.res = resp;
          this.getRecordList();
        }
      })
    },
    getRecordList() {
      // 组装开始结束时间
      if (this.searchRecord.time !== null && this.searchRecord.time.length === 2) {
        this.searchRecord.startTime = this.searchRecord.time[0];
        this.searchRecord.endTime = this.searchRecord.time[1];
      } else {
        this.searchRecord.startTime = null;
        this.searchRecord.endTime = null;
      }

      this.loading = true;
      this.postRequest("/work/translate/getRecordList", this.searchRecord).then(resp => {
        this.loading = false;
        if (resp) {
          this.recordData = resp.data;
          this.total = resp.total;
        }
      })
    },
    currentChange(page) {
      this.searchRecord.currentPage = page;
      this.getRecordList();
    },
    sizeChange(size) {
      this.searchRecord.size = size;
      this.getRecordList();
    },
    initRoles() {
      this.getRequest("/sys/role/getAll").then(resp => {
        if (resp) {
          this.roles = resp;
        }
      })
    },
    deleteRecord(data) {
      this.$confirm(
          "此操作将永久删除[" + data.createTime + "]时间的记录, 是否继续?",
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
      )
          .then(() => {
            this.deleteRequest("/work/translate/" + data.id).then((resp) => {
              if (resp) {
                this.getRecordList();
              }
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
    },
    showRecord(data) {
      this.dialogVisible = true;
      this.showData = data;
    }
  }
}
</script>

<style>
.box-card {
  margin-top: 30px;
  font-size: 18px;
}

.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}

label {
  display: inline-block;
  width: 100px;
  text-align: right;
}
</style>