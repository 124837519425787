<template>
  <div>
    <div>
      <div>
        <el-input
            style="width: 200px;margin-right: 20px"
            size="mini"
            placeholder="角色名称"
            v-model="searchYsRole.name"
            clearable
            @keydown.enter.native="searchYsRole.currentPage = 1; searchYsRole.size = 10; initYsRole()"
        ></el-input>
        <el-select
            v-model="searchYsRole.address"
            size="mini"
            style="width: 200px;margin-right: 20px"
            placeholder="角色所属地区"
            clearable
            filterable
            @change="searchYsRole.currentPage = 1; searchYsRole.size = 10; initYsRole()"
        >
          <el-option
              v-for="item in ysAddress"
              :key="item"
              :label="item"
              :value="item"
          >
          </el-option>
        </el-select>
        <el-select
            v-model="searchYsRole.star"
            size="mini"
            style="width: 200px;margin-right: 20px"
            placeholder="角色星级"
            clearable
            filterable
            @change="searchYsRole.currentPage = 1; searchYsRole.size = 10; initYsRole()"
        >
          <el-option
              v-for="item in stars"
              :key="item"
              :label="item"
              :value="item"
          >
          </el-option>
        </el-select>
        <el-select
            v-model="searchYsRole.attributes"
            size="mini"
            style="width: 200px;margin-right: 20px"
            placeholder="角色属性"
            clearable
            filterable
            @change="searchYsRole.currentPage = 1; searchYsRole.size = 10; initYsRole()"
        >
          <el-option
              v-for="item in attributes"
              :key="item"
              :label="item"
              :value="item"
          >
          </el-option>
        </el-select>
      </div>
      <div style="margin-top: 10px">
        <el-select
            v-model="searchYsRole.position"
            size="mini"
            style="width: 200px;margin-right: 20px"
            placeholder="角色定位"
            clearable
            filterable
            @change="searchYsRole.currentPage = 1; searchYsRole.size = 10; initYsRole()"
        >
          <el-option
              v-for="item in positions"
              :key="item"
              :label="item"
              :value="item"
          >
          </el-option>
        </el-select>
        <el-button icon="el-icon-delete" type="warning" size="mini" @click="resetYsRole">
          重置
        </el-button>
        <el-button
            type="success"
            size="mini"
            icon="el-icon-search"
            @click="searchYsRole.currentPage = 1; searchYsRole.size = 10; initYsRole()"
        >
          搜索
        </el-button>
        <el-button
            type="success"
            size="mini"
            icon="el-icon-download"
            @click="exportData"
        >
          导出数据
        </el-button>
        <el-button
            type="primary"
            size="mini"
            icon="el-icon-plus"
            @click="showAddYsRoleView"
            v-if="roleButton.buttonLevelComponents !== null && roleButton.buttonLevelComponents.indexOf('YsRole') !== -1"
        >
          添加角色
        </el-button>
      </div>
    </div>
    <div style="margin-top: 10px">
      <el-table
          size="mini"
          :data="ysRoleData"
          border
          stripe
          :row-class-name="tableRowClassName"
          v-loading="loading"
          element-loading-text="拼命加载中"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.8)"
      >
        <el-table-column
            label="角色名称"
            align="center"
            fixed
            prop="name"
            width="90"
        ></el-table-column>
        <el-table-column
            label="角色所属地区"
            align="center"
            fixed
            prop="address"
            width="100"
        ></el-table-column>
        <el-table-column
            label="角色等级"
            align="center"
            fixed
            prop="level"
            width="70"
        ></el-table-column>
        <el-table-column
            label="角色定位"
            align="center"
            width="100"
        >
          <template slot-scope="scope">
            <el-tag type="success">{{ scope.row.position }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column
            label="角色星级"
            align="center"
            width="70"
        >
          <template slot-scope="scope">
            <el-tag type="primary">{{ scope.row.star }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column
            label="角色属性"
            align="center"
            width="70"
        >
          <template slot-scope="scope">
            <el-tag type="danger">{{ scope.row.attributes }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column
            label="角色命座"
            align="center"
            width="70"
        >
          <template slot-scope="scope">
            <el-tag type="primary">{{ scope.row.lifeSeat }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column
            label="武器"
            align="center"
            width="120"
        >
          <template slot-scope="scope">
            <el-tag type="success">{{ scope.row.ysarms.name }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column
            label="圣遗物"
            align="center"
            width="120"
        >
          <template slot-scope="scope">
            <el-tag type="success">{{ scope.row.ysholyRelic.name }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column
            label="重要加成名称"
            align="center"
            width="120"
        >
          <template slot-scope="scope">
            <el-tag v-if="scope.row.importantAddition" type="danger">{{ scope.row.importantAddition }}</el-tag>
            <el-tag v-else type="danger">-</el-tag>
          </template>
        </el-table-column>
        <el-table-column
            label="生命值"
            align="center"
        >
          <template slot-scope="scope">
            <el-tag type="primary">{{ scope.row.life }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column
            label="攻击力"
            align="center"
        >
          <template slot-scope="scope">
            <el-tag type="primary">{{ scope.row.attackPower }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column
            label="防御力"
            align="center"
        >
          <template slot-scope="scope">
            <el-tag type="primary">{{ scope.row.defense }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column
            label="元素精通"
            align="center"
        >
          <template slot-scope="scope">
            <el-tag type="primary">{{ scope.row.elementMastery }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column
            label="元素充能效率"
            align="center"
            width="100"
        >
          <template slot-scope="scope">
            <el-tag type="primary">{{ scope.row.elementalChargingEfficiency }}%</el-tag>
          </template>
        </el-table-column>
        <el-table-column
            label="暴击率"
            align="center"
        >
          <template slot-scope="scope">
            <el-tag type="primary">{{ scope.row.critRate }}%</el-tag>
          </template>
        </el-table-column>
        <el-table-column
            label="暴击伤害"
            align="center"
            width="100"
        >
          <template slot-scope="scope">
            <el-tag type="primary">{{ scope.row.criticalDamage }}%</el-tag>
          </template>
        </el-table-column>
        <el-table-column
            label="治疗加成"
            align="center"
        >
          <template slot-scope="scope">
            <el-tag type="primary">{{ scope.row.healingBonus }}%</el-tag>
          </template>
        </el-table-column>
        <el-table-column
            label="对应元素加成"
            align="center"
            width="100"
        >
          <template slot-scope="scope">
            <el-tag type="primary">{{ scope.row.correspondingElementBonus }}%</el-tag>
          </template>
        </el-table-column>
        <el-table-column
            label="其他备注"
            align="center"
            prop="remark"
            width="200"
        ></el-table-column>
        <el-table-column
            label="天赋等级(格式: x/x/x)"
            align="center"
            width="145"
        >
          <template slot-scope="scope">
            <el-tag type="success">{{ scope.row.talentLevel }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column
            label="操作"
            fixed="right"
            width="100"
        >
          <template slot-scope="scope">
            <el-button v-if="roleButton.buttonLevelComponents !== null && roleButton.buttonLevelComponents.indexOf('YsRole') !== -1" size="mini" style="padding: 3px" type="primary" @click="showUpdateYsRoleView(scope.row)">编辑</el-button>
            <el-button v-if="roleButton.buttonLevelComponents !== null && roleButton.buttonLevelComponents.indexOf('YsRole') !== -1" size="mini" style="padding: 3px" type="danger" @click="deleteYsRole(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div style="display: flex; justify-content: flex-end; margin-top: 5px">
      <el-pagination
          background
          layout="sizes, prev, pager, next, jumper, ->, total, slot"
          :total="total"
          :current-page="searchYsRole.currentPage"
          :page-size="searchYsRole.size"
          @current-change="currentChange"
          @size-change="sizeChange"
      >
      </el-pagination>
    </div>

    <el-dialog
        :close-on-click-modal="false"
        :title="title"
        :visible.sync="dialogVisible"
        width="1335px">
      <el-form ref="ysRoleForm" :model="ysRole" :rules="rules" label-width="135px">
        <el-row :gutter="20">
          <el-col :span="6">
            <el-form-item label="角色名称" prop="name">
              <el-input
                  size="mini"
                  style="width: 150px"
                  v-model="ysRole.name"
                  placeholder="请输入角色名称"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="角色等级" prop="level">
              <el-input
                  size="mini"
                  style="width: 150px"
                  v-model="ysRole.level"
                  placeholder="请输入角色等级"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="角色星级" prop="star">
              <el-select
                  v-model="ysRole.star"
                  size="mini"
                  style="width: 150px"
                  placeholder="角色星级"
                  clearable
                  filterable
              >
                <el-option
                    v-for="item in stars"
                    :key="item"
                    :label="item"
                    :value="item"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="角色属性" prop="attributes">
              <el-select
                  v-model="ysRole.attributes"
                  size="mini"
                  style="width: 150px"
                  placeholder="角色属性"
                  clearable
                  filterable
              >
                <el-option
                    v-for="item in attributes"
                    :key="item"
                    :label="item"
                    :value="item"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="6">
            <el-form-item label="角色命座" prop="lifeSeat">
              <el-select
                  v-model="ysRole.lifeSeat"
                  size="mini"
                  style="width: 150px"
                  placeholder="角色命座"
                  clearable
                  filterable
              >
                <el-option
                    v-for="item in lifeSeats"
                    :key="item"
                    :label="item"
                    :value="item"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="角色定位" prop="position">
              <el-select
                  v-model="ysRole.position"
                  size="mini"
                  style="width: 150px"
                  placeholder="角色定位"
                  clearable
                  filterable
              >
                <el-option
                    v-for="item in positions"
                    :key="item"
                    :label="item"
                    :value="item"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="武器" prop="armsId">
              <el-select
                  v-model="ysRole.armsId"
                  size="mini"
                  style="width: 150px"
                  placeholder="武器"
                  clearable
                  filterable
              >
                <el-option
                    v-for="item in arms"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="圣遗物" prop="holyRelicId">
              <el-select
                  v-model="ysRole.holyRelicId"
                  size="mini"
                  style="width: 150px"
                  placeholder="圣遗物"
                  clearable
                  filterable
              >
                <el-option
                    v-for="item in holyRelics"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="6">
            <el-form-item label="重要加成名称">
              <el-select
                  v-model="ysRole.importantAddition"
                  size="mini"
                  style="width: 150px"
                  placeholder="重要加成名称"
                  clearable
                  filterable
              >
                <el-option
                    v-for="item in entries"
                    :key="item"
                    :label="item"
                    :value="item"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="生命值" prop="life">
              <el-input
                  size="mini"
                  style="width: 150px"
                  v-model="ysRole.life"
                  placeholder="生命值"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="攻击力" prop="attackPower">
              <el-input
                  size="mini"
                  style="width: 150px"
                  v-model="ysRole.attackPower"
                  placeholder="攻击力"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="防御力" prop="defense">
              <el-input
                  size="mini"
                  style="width: 150px"
                  v-model="ysRole.defense"
                  placeholder="防御力"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="6">
            <el-form-item label="元素精通" prop="elementMastery">
              <el-input
                  size="mini"
                  style="width: 150px"
                  v-model="ysRole.elementMastery"
                  placeholder="元素精通"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="元素充能效率" prop="elementalChargingEfficiency">
              <el-input
                  size="mini"
                  style="width: 150px"
                  v-model="ysRole.elementalChargingEfficiency"
                  placeholder="元素充能效率(%)"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="暴击率" prop="critRate">
              <el-input
                  size="mini"
                  style="width: 150px"
                  v-model="ysRole.critRate"
                  placeholder="暴击率(%)"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="暴击伤害" prop="criticalDamage">
              <el-input
                  size="mini"
                  style="width: 150px"
                  v-model="ysRole.criticalDamage"
                  placeholder="暴击伤害(%)"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="6">
            <el-form-item label="治疗加成" prop="healingBonus">
              <el-input
                  size="mini"
                  style="width: 150px"
                  v-model="ysRole.healingBonus"
                  placeholder="治疗加成(%)"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="对应元素加成" prop="correspondingElementBonus">
              <el-input
                  size="mini"
                  style="width: 150px"
                  v-model="ysRole.correspondingElementBonus"
                  placeholder="对应元素加成(%)"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="天赋等级" prop="talentLevel">
              <el-input
                  size="mini"
                  style="width: 150px"
                  v-model="ysRole.talentLevel"
                  placeholder="格式: x/x/x"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="其他备注" prop="remark">
              <el-input
                  size="mini"
                  style="width: 150px"
                  v-model="ysRole.remark"
                  placeholder="其他备注"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="6">
            <el-form-item label="角色所属地区" prop="address">
              <el-select
                  v-model="ysRole.address"
                  size="mini"
                  style="width: 150px"
                  placeholder="角色所属地区"
                  clearable
                  filterable
              >
                <el-option
                    v-for="item in ysAddress"
                    :key="item"
                    :label="item"
                    :value="item"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="doYsRole">确 定</el-button>
        </span>
    </el-dialog>

  </div>
</template>

<script>
export default {
  name: "YsRole",
  data() {
    return {
      ysRoleData: [],
      loading: false,
      searchYsRole: {
        name: "",
        address: "",
        star: null,
        attributes: "",
        position: "",
        currentPage: 1,
        size: 10,
      },
      ysRole: {
        id: null,
        name: "",
        address: "",
        level: "",
        star: "",
        attributes: "",
        lifeSeat: "",
        position: "",
        armsId: null,
        holyRelicId: null,
        importantAddition: "",
        life: "",
        attackPower: "",
        defense: "",
        elementMastery: "",
        elementalChargingEfficiency: "",
        critRate: "",
        criticalDamage: "",
        healingBonus: "",
        correspondingElementBonus: "",
        talentLevel: "",
        remark: ""
      },
      total: 0,
      dialogVisible: false,
      title: '',
      rules: {
        name: [{required: true, message: "请输入角色名称", trigger: "blur"}],
        address: [{required: true, message: "请输入角色所属地区", trigger: "blur"}],
        level: [{required: true, message: "请输入角色等级", trigger: "blur"}],
        star: [{required: true, message: "请选择角色星级", trigger: "blur"}],
        attributes: [{required: true, message: "请选择角色属性", trigger: "blur"}],
        lifeSeat: [{required: true, message: "请选择角色命座", trigger: "blur"}],
        position: [{required: true, message: "请选择角色定位", trigger: "blur"}],
        armsId: [{required: true, message: "请选择武器", trigger: "blur"}],
        holyRelicId: [{required: true, message: "请选择圣遗物", trigger: "blur"}],
        life: [{required: true, message: "请输入生命值", trigger: "blur"}],
        attackPower: [{required: true, message: "请输入攻击力", trigger: "blur"}],
        defense: [{required: true, message: "请输入防御力", trigger: "blur"}],
        elementMastery: [{required: true, message: "请输入元素精通", trigger: "blur"}],
        elementalChargingEfficiency: [{required: true, message: "请输入元素充能效率(%)", trigger: "blur"}],
        critRate: [{required: true, message: "请输入暴击率(%)", trigger: "blur"}],
        criticalDamage: [{required: true, message: "请输入暴击伤害(%)", trigger: "blur"}],
        healingBonus: [{required: true, message: "请输入治疗加成(%)", trigger: "blur"}],
        correspondingElementBonus: [{required: true, message: "请输入对应元素加成(%)", trigger: "blur"}],
        talentLevel: [{required: true, message: "请输入天赋等级", trigger: "blur"}],
      },
      arms: [],
      holyRelics: [],
    }
  },
  computed: {
    stars() {
      return this.$store.state.stars;
    },
    entries() {
      return this.$store.state.entries;
    },
    lifeSeats() {
      return this.$store.state.lifeSeats;
    },
    attributes() {
      return this.$store.state.attributes;
    },
    positions() {
      return this.$store.state.positions;
    },
    ysAddress() {
      return this.$store.state.ysAddresses;
    },
    roleButton() {
      return this.$store.state.currentRole;
    }
  },
  mounted() {
    this.initYsRole();
    this.initArms();
    this.initYsholyRelic();
  },
  methods: {
    tableRowClassName({row, rowIndex}) {
      if (rowIndex % 2 !== 0) {
        return 'warning-row';
      } else if (rowIndex % 2 === 0) {
        return 'success-row';
      }
      return '';
    },
    initYsRole() {
      this.loading = true;
      this.postRequest("/ys/role/getPageData", this.searchYsRole).then(resp => {
        this.loading = false;
        if (resp) {
          this.ysRoleData = resp.data;
          this.total = resp.total;
        }
      })
    },
    initArms() {
      this.getRequest("/ys/arms/getAll").then(resp => {
        if (resp) {
          this.arms = resp;
        }
      })
    },
    initYsholyRelic() {
      this.getRequest("/ys/holy-relic/getAll").then(resp => {
        if (resp) {
          this.holyRelics = resp;
        }
      })
    },
    currentChange(page) {
      this.searchYsRole.currentPage = page;
      this.initYsRole();
    },
    sizeChange(size) {
      this.searchYsRole.size = size;
      this.initYsRole();
    },
    deleteYsRole(data) {
      this.$confirm(
          "此操作将永久删除[" + data.name + "]角色, 是否继续?",
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
      ).then(() => {
        this.deleteRequest("/ys/role/" + data.id).then(resp => {
              if (resp) {
                this.initYsRole();
              }
            }
        );
      }).catch(() => {
        this.$message({
          type: "info",
          message: "已取消删除",
        });
      });
    },
    showAddYsRoleView() {
      this.ysRole = {
        id: null,
        name: "",
        address: "",
        level: "",
        star: "",
        attributes: "",
        lifeSeat: "",
        position: "",
        armsId: null,
        holyRelicId: null,
        importantAddition: "",
        life: "",
        attackPower: "",
        defense: "",
        elementMastery: "",
        elementalChargingEfficiency: "",
        critRate: "",
        criticalDamage: "",
        healingBonus: "",
        correspondingElementBonus: "",
        talentLevel: "",
        remark: ""
      };
      this.title = "添加角色";
      this.dialogVisible = true;
    },
    showUpdateYsRoleView(data) {
      // 拷贝
      this.ysRole = Object.assign({}, data);
      this.title = "编辑角色";
      this.dialogVisible = true;
    },
    doYsRole() {
      if (this.ysRole.id) {
        this.$refs['ysRoleForm'].validate((valid) => {
          if (valid) {
            this.putRequest("/ys/role", this.ysRole).then(resp => {
              if (resp) {
                this.dialogVisible = false;
                this.initYsRole();
              }
            })
          } else {
            console.log('error submit!!');
            return false;
          }
        })
      } else {
        this.$refs['ysRoleForm'].validate((valid) => {
          if (valid) {
            this.postRequest("/ys/role", this.ysRole).then(resp => {
              if (resp) {
                this.dialogVisible = false;
                this.initYsRole();
              }
            })
          } else {
            console.log('error submit!!');
            return false;
          }
        })
      }
    },
    exportData() {
      this.downloadRequest("/ys/role/export");
    },
    resetYsRole() {
      this.searchYsRole.name = '';
      this.searchYsRole.address = '';
      this.searchYsRole.star = null;
      this.searchYsRole.attributes = '';
      this.searchYsRole.position = '';
    }
  }
}
</script>

<style>
.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}

label {
  display: inline-block;
  width: 150px;
  text-align: right;
}
</style>